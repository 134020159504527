import "./TripAnalysis.css";
import React, { useState, useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import { getTripRouteMapData } from './../../_services/controlSummary.service';
import mapBgSvg from '../../Assets/map_stroke.svg';
import grnBlank from '../../Assets/markers/grn-blank.png';
import bluBlank from '../../Assets/markers/blu-blank.png';
import pinkBlank from '../../Assets/markers/pink-blank.png';
import placemarkCircle from '../../Assets/markers/placemark_circle.png';
import Milestone from '../../Assets/markers/Milestone.png';

import carIcon from '../../Assets/carIcon.png';


let poly = [];
let timeoutId = null;

function TripAnalysisAndMap({ companyId, tracknumb }) {
    const [swiperData, setSwiperData] = useState([]);


    const mapContainer = useRef(null);
    const mapSpeed = useRef(200);
    const routeType = useRef('b');

    const tableBodyEl = useRef(null);

    const addTableRowAndMarker = function (idx, event_name, dttime, latlng, runKMS, location = "") {
        let tableRowEl = document.createElement('tr');
        tableRowEl.style.cursor = 'pointer'
        tableRowEl.innerHTML = `                
                    <td>
                        <span>${idx > 9 ? idx : '0' + idx}</span>
                        <div></div>
                    </td>
                    <td style="padding:10px 10px" title=" ${runKMS ? `KMS : ${runKMS}` : ''} ${location ? `Location : ${location}` : ''} Event : ${event_name}"
                        >
                        ${event_name}
                        <br />
                        <span class="divLeftDataHeading">Date :</span>
                            ${dttime}
                    </td>                            
                `;

        let imgIdx = 8;
        if (event_name.toLowerCase().indexOf("origin entry") !== -1)
            imgIdx = 1;
        else if (event_name.toLowerCase().indexOf("customer entry") !== -1)
            imgIdx = 2;

        let mk = L.marker(latlng, {
            icon: L.icon({
                iconUrl: imgIdx === 8 ? placemarkCircle : imgIdx === 1 ? grnBlank : imgIdx === 2 ? bluBlank : placemarkCircle,
                iconSize: [30, 30]
            })
        })
        tableRowEl.addEventListener('mouseover', function (event) {
            L.DomUtil.addClass(mk._icon, "bounce");
        })
        tableRowEl.addEventListener('mouseout', function (event) {
            L.DomUtil.removeClass(mk._icon, "bounce");
        })

        tableBodyEl.current.insertAdjacentElement("beforeend", tableRowEl);
        let eventMsg = `<p>Event : ${event_name}</p><p>Date : ${dttime}</p>`;
        if (runKMS)
            eventMsg += `<p>KM since start : ${runKMS}</p>`;
        if (location)
            eventMsg += `<p>Location : ${location}</p>`;

        mk.bindPopup(eventMsg)
        mk.addTo(mmiState.mmiLayerGroup)
    }

    function createSite(lat, lng, distLat, distLong) {
        let latis = [lat + distLat, lat - distLat, lat - distLat, lat + distLat, lat + distLat];
        let longis = [lng + distLong, lng + distLong, lng - distLong, lng - distLong, lng + distLong];

        let path = [];

        for (let i = 0; i < latis.length; i++) {
            let latlng = [latis[i], longis[i]];
            path.push(latlng);
        }
        return path;
    }


    const drawRoute = function (type = null) {
        if (!swiperData) return;

        //removePolyline();
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        mmiState.mmiLayerGroup.clearLayers();

        tableBodyEl.current.innerHTML = ''




        //Polyline css
        routeType.current
        let linecss = {
            color: routeType.current === 'r' ? "#FF0000" :'black',
            weight: 3,
            opacity: 1
        };

        let points = [];
        let line;
        let w = 14, h = 33;
        let idx = 1;
        let marker = null;

        let path, tripDetails = "", tmpData = "";
        let flgReturn = 0, lastDest = "";

        // origin site
        if (swiperData && swiperData.result) {
            let item = swiperData.result[0];
            if (isNaN(item["origlat"]) == false && parseFloat(item["origlat"]) != 0) {
                let oMarker = L.marker([item["origlat"], item["origlong"]], {
                    icon: L.icon({
                        iconUrl: pinkBlank,
                        iconAnchor: [30 / 2, 30 / 2], //Handles the marker anchor point. For a correct anchor point [ImageWidth/2,ImageHeight/2]
                        iconSize: [30, 30]

                    })
                });
                oMarker.bindPopup(`<p>${item["origin"]}</p>`);
                oMarker.addTo(mmiState.mmiLayerGroup);

                let path = createSite(item["origlat"], item["origlong"], item["origdistlat"], item["origdistlong"]);
                console.log(path)

                L.polygon([...path], { color: 'red', fill: false }).addTo(mmiState.mmiLayerGroup)

            }

        }
        // origin site


        for (let item of swiperData.result) {
            if (item["isreturn"] == true)
                flgReturn = 1;
            if (tmpData.indexOf(item["destlat"] + "#" + item["destlong"]) == -1 && isNaN(item["destlat"]) == false) {
                tmpData += item["destlat"] + "#" + item["destlong"] + ",";

                //--------Destination Details ------------------------

                if (parseFloat(item["destlat"]) != 0 && item["isreturn"] == false) {

                    let dMarker = L.marker([item["destlat"], item["destlong"]], {
                        icon: L.icon({
                            iconUrl: pinkBlank,
                            iconAnchor: [30 / 2, 30 / 2], //Handles the marker anchor point. For a correct anchor point [ImageWidth/2,ImageHeight/2]
                            iconSize: [30, 30]

                        })
                    });
                    dMarker.bindPopup(`<p>${item["destination"]}</p>`);
                    dMarker.addTo(mmiState.mmiLayerGroup);
                    let path = createSite(item["destlat"], item["destlong"], item["distlat"], item["distlong"]);
                    L.polygon([...path], { color: 'red', fill: false }).addTo(mmiState.mmiLayerGroup)


                    //----------Create mother site geofence circle ------------------------
                    if (isNaN(item["mothersiteradiuskm"]) == false && item["mothersiteradiuskm"] > 0) {

                        var circleOptions = {
                            color: 'red',
                            fillColor: '#b2b2ff',
                            fillOpacity: 1,
                            weight: 1,
                            color: '#7f7fff',
                            radius: item["mothersiteradiuskm"] * 1000

                        }
                        var tmpCircle = L.circle([item["destlong"], item["destlat"]], circleOptions).addTo(mmiState.mmiLayerGroup);
                        console.log(tmpCircle)

                    }
                }

            }
        }



        line = L.polyline([], linecss).addTo(mmiState.mmiLayerGroup) //add polyline on map

        function redraw({ lat = null, long = null, dttime = null, event_name = '', runKMS = null, ...rest }) {

            if (!lat) return;

            let latlng = [lat, long]
            if (!marker) {
                marker = L.marker(latlng, {
                    icon: L.icon({
                        iconUrl: carIcon,
                        iconAnchor: [w / 2, h / 2], //Handles the marker anchor point. For a correct anchor point [ImageWidth/2,ImageHeight/2]
                        iconSize: [14, 33]

                    })
                }).addTo(mmiState.mmiLayerGroup)

            }
            line.addLatLng(latlng);
            marker.setLatLng(latlng);

            //console.log('awd',event_name)

            if (event_name) {
                //console.log(event_name)
                addTableRowAndMarker(idx, event_name, dttime, latlng, runKMS)
                idx++;
            }


        }


        if (routeType.current === 'b') {
            points = [...swiperData.onward, ...swiperData.return];
        } else if (routeType.current === 'o') {
            points = [...swiperData.onward];
        } else if (routeType.current === 'r') {
            points = [...swiperData.return];
        }

        if (type === 'loadAll') {
            for (let point of points) {
                redraw(point);
                mmiState.mmiMap.fitBounds(mmiState.mmiLayerGroup.getBounds())
            }

        } else if (type === 'start') {
            function update() {
                if (points.length) {

                    redraw(points.shift());
                    mmiState.mmiMap.fitBounds(mmiState.mmiLayerGroup.getBounds())
                    timeoutId = setTimeout(update, mapSpeed.current);
                }
            }
            timeoutId = setTimeout(update, mapSpeed.current);

        }


    }

    const handleSpeedChange = function (e) {
        const value = e.target.value;
        //console.log(value);
        mapSpeed.current = value;
    }

    const handleRouteTypeChange = function (e) {
        const value = e.target.value;
        //console.log(value);
        routeType.current = value;
    }

    useEffect(() => {

        if (!mmiState.isInitialised) {
            initMmiMap()
        } else {
            mmiState.mmiLayerGroup.clearLayers();
        }

        document.querySelector('.tripAnalysis').classList.add('simulateLoading');

        getTripRouteMapData({ companyId, tracknumb }).then((res) => {
            //console.log(res, res.result)
            setSwiperData(res.result);
            //setSwiperData(data1)

            mmiState.mmiMapEl.style.display = ''
            moveMmiMapTo(mapContainer.current);
            document.querySelector('.tripAnalysis').classList.remove('simulateLoading');

        }).catch(err => {
            document.querySelector('.tripAnalysis').classList.remove('simulateLoading');
            console.log(err)
        })



        return () => {

            clearTimeout(timeoutId)
            mmiState.mmiLayerGroup.clearLayers();

            mmiState.mmiMapEl.style.display = 'none';
            moveMmiMapTo(document.body)

        }

    }, [])

    useEffect(() => {
        if (swiperData?.result) {
            //console.log(swiperData);
            drawRoute('start');
        }
    }, [swiperData])



    return (

        <div className="tripAnalysis" >
            <div className="tripAnalysis__top">
                <div className="tripAnalysis__top__left__parent"
                    style={{ width: "31%", backgroundImage: "url('" + mapBgSvg + "')" }}>
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {
                            swiperData["result"]?.map((d, i) => {

                                return (
                                    <SwiperSlide key={i}>
                                        <div className="tripAnalysis__top__left">
                                            <span>{i + 1 <= 9 ? "0" + (i + 1) : i}</span>
                                            <div>
                                                <InfoDetails title="Vehicle" value={d["vehicle_name"]} />
                                                <InfoDetails title="Date" value={d["invoicedate"]} />
                                            </div>
                                            <div>
                                                <InfoDetails title="Invoice/PGI" value={d["invoice"]} />
                                            </div>
                                            <div>
                                                <InfoDetails title="Origin" value={d["isreturn"] ? "" : d["origin"]} />
                                                <InfoDetails title="Destination" value={d["destination"]} />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )

                            })
                        }


                    </Swiper>

                </div>
                <div className="tripAnalysis__top__right" style={{ width: "66%" }}>
                    <div style={{ minHeight: "50px", background: "#f4f6fa", borderRadius: "10px" }}>
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {
                                swiperData["result"]?.map((d, i) => {

                                    return (
                                        <SwiperSlide key={i}>
                                            <div>
                                                <div className="tripAnalysis__top__right__subTop">
                                                    <InfoDetails title="Status" value={d["status"]} />
                                                    <InfoDetails title="System(Lead) Distance" value={d["googledistance"]} />
                                                    <InfoDetails title="Travel Distance" value={d["actualdistance"]} />
                                                    <InfoDetails title="Customer Entry" value={d["destentry"]} />
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    )

                                })
                            }




                        </Swiper>
                    </div>

                    <div className="mapHelper">
                        <div className="mapHelperIcons">
                            <div className="centerMyElement " style={{ backgroundColor: "#EEFFF0" }}>
                                <img
                                    src={grnBlank}
                                    alt=""
                                />
                                Plant Entry
                            </div>
                            <div className="centerMyElement " style={{ backgroundColor: "#EEEBFF" }}>
                                <img
                                    src={bluBlank}
                                    alt=""
                                />
                                Customer Entry
                            </div>
                            <div className="centerMyElement " style={{ backgroundColor: "#F4F6FA" }}>
                                <img
                                    src={placemarkCircle}
                                    alt=""
                                />
                                Stoppage
                            </div>
                            <div className="centerMyElement " style={{ backgroundColor: "#FFE6F0" }}>
                                <img
                                    src={pinkBlank}
                                    alt=""
                                />
                                Site (Geofences)
                            </div>
                            <div className="centerMyElement " style={{ backgroundColor: "#FFEEF0" }}>
                                <img
                                    src={Milestone}
                                    alt=""
                                />
                                Milestones
                            </div>
                        </div>
                        <div className="mapControls mapHelperIcons" style={{ alignItems: 'center' }}>
                            <div className="centerMyElement " style={{ padding: '5px' }}>
                                <select onChange={handleRouteTypeChange} style={{ border: 'none', color: '#1a202e', backgroundColor: '#e5e7ea', padding: '5px 11px', borderRadius: '7px' }}>
                                    <option value={'b'}>Both</option>
                                    <option value={'o'}>Onward</option>
                                    <option value={'r'}>Return</option>
                                </select>

                            </div>

                            <div className="centerMyElement " style={{ padding: '5px' }}>
                                Speed: &nbsp;
                                <select onChange={handleSpeedChange} style={{ border: 'none', color: '#1a202e', backgroundColor: '#e5e7ea', padding: '5px 11px', borderRadius: '7px' }}>
                                    <option value={200}>Low</option>
                                    <option value={100}>Medium</option>
                                    <option value={10}>High</option>
                                </select>
                            </div>
                            <div className="centerMyElement " style={{ padding: '5px' }}>
                                <button onClick={() => drawRoute('start')} style={{ backgroundColor: '#E59515', color: 'white', border: 'none', padding: '6px 21px', borderRadius: '7px', fontSize: '11px !important' }}>Start Route</button>
                            </div>
                            <div className="centerMyElement " style={{ padding: '5px' }}>
                                <button onClick={() => drawRoute('loadAll')} style={{ backgroundColor: '#1a202e', color: 'white', border: 'none', padding: '6px 21px', borderRadius: '7px', fontSize: '11px !important' }}>Load All</button>
                            </div>
                            <div className="centerMyElement " style={{ backgroundColor: "#F4F6FA" }}>
                                <span style={{ color: "#8c8d98" }}>Track Number :</span>&nbsp; {tracknumb}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tripAnalysis__bottom">
                <div style={{ width: "26%" }} className="mapTableInfo">
                    <table>
                        <thead>
                            <tr className="tripAnalysis__tblHeader">
                                <th style={{ padding: "10px 20px", width: "20px" }}> SN</th>
                                <th style={{ padding: "10px", textAlign: "left" }}>
                                    Event - Date
                                </th>
                            </tr>
                        </thead>
                        <tbody ref={tableBodyEl}>
                        </tbody>
                    </table>
                </div>

                <div className="mapDiv">
                    <div ref={mapContainer} >

                    </div>
                </div>

            </div>
        </div>

    )
}

const InfoDetails = ({ title = "No Title", value = "No Value Provided" }) => {
    return (
        <div>
            <p className="infoDetails__heading">{title}</p>
            <p style={{ fontWeight: "500" }}>{value}</p>
        </div>
    );
};


export default TripAnalysisAndMap;
