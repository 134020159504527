import { authHeader } from '../_helpers';
import { handleResponse } from './handleResponse';
import { BASE_URL } from '../_constants';


export function saveComment(commentData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(commentData)
        
    };
    // http://localhost:14213/api/1.0/ticket/save-comment
    return fetch(
        `${BASE_URL}/save-comment`,
        requestOptions
    ).then(handleResponse);
}