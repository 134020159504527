// http://localhost:14213/getcontroldatasummary?companyId=18849&fromDt=03/12/2022&toDt=03/14/2022
import { controlSummaryConstants } from '../_constants';

export function controlSummary(state = { isLoading: false, data: { totalcontrolData: [], last24hrlData: [], last7DaylData: [] } }, action) {
    switch (action.type) {
        case controlSummaryConstants.CONTROL_SUMMARY_REQUEST:
            return { ...state, isLoading: true };
        case controlSummaryConstants.CONTROL_SUMMARY_SUCCESS:
            return { ...state, data: action.data.result, isLoading: false };
        case controlSummaryConstants.CONTROL_SUMMARY_ERROR:
            return { ...state, isLoading: false };
        case controlSummaryConstants.CONTROL_SUMMARY_UPDATE:
            return { ...state };
        default:
            return state;
    }
}
