import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { Home } from '../Home';
import { Login } from '../Login';
import { RegisterPage } from '../RegisterPage';
import Message from './../_components/Message'

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            //this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        
        return (
            
            <div className="CustomScroll CustomScrollBar" style={{fontFamily:"Montserrat"}}>

                {/*{alert.message && (<div className={`alert ${alert.type} fade show`} role="alert">*/}
                {/*    {alert.message}*/}                   
                {/*</div> )}*/}
                {alert?.type === 'alert-danger' && alert?.message && <Message>{alert.message}</Message>}
               

                <Router history={history}>
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={RegisterPage} />                               
                        <Redirect from="*" to="/" />
                    </Switch>
                </Router>
                
            </div>        
             
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };