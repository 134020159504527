import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Draggable from "react-draggable";
import { ControlSummary } from "../ControlSummary";
import {
  getControlTicketData,
  saveComment,
  getInvoiceWiseData,
  getCidData,
  getServiceAlertsData,
} from "../../_services";
import { getColorCodeFromType, downloadExcel } from "../../_helpers";
import { decode as base64_decode } from "base-64";
import Calender from "../Calender/CalenderView";
import Modal from "../Portal/Portal";
import TripAnalysisAndMap from "../TripAnalysis/TripAnalysis";
import "./Dashboard.css";
import ReactDOM from "react-dom";

import { v4 as uuidv4 } from "uuid";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InfiniteScroll from "react-infinite-scroll-component";

import BellIcon from "../../Assets/NotificationBell.png";
import MemberIcon from "../../Assets/Members.svg";
import RecordSearch from "../../Assets/RecordSearch.svg";
import SubTicketLink from "../../Assets/SubTicketLink.svg";
import DropDownIcon from "../../Assets/Dropdown.svg";
import FilterIcon from "../../Assets/FilterIcon.svg";
import WhiteSearchIcon from "../../Assets/WhiteSearchIcon.svg";
import TicketIcon from "../../Assets/TicketIcon.svg";
import chatIcon from "../../Assets/chatIcon.svg";
import SendIcon from "../../Assets/SendIcon.svg";
import DropDownBlackIcon from "../../Assets/DropdownBlack.svg";
import Logout from "../../Assets/logout.svg";
import threeDots from "../../Assets/threedots.png";
import UserIcon from "../../Assets/UserIcon.svg";
import CloseIcon from "../../Assets/CloseButton.svg";
import leftArrow from "../../Assets/leftArrow.svg";
import rightArrow from "../../Assets/rightArrow.svg";
import mapIcon from "../../Assets/mapIcon.svg";

import Message from "./../Message";
import Dropdown from "./Dropdown";

export const debouncedFn = (fn, delay) => {
  let timerId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

const filterRecords = (data) => {
  return {
    //filterObject(obj, callback) {
    //    return Object.fromEntries(Object.entries(obj).filter(([key, val], i) => callback(val, key)));
    //},
    filterCommentsOfUser(uName) {
      return Object.entries(data).reduce((acc, curr) => {
        let [key, value] = curr;
        let { vehicleName, invoice } = value[0]; //array of objects tickets ..grouped

        let filteredTicketData = value.filter((d) => {
          if (d.comments instanceof Array && d.comments.length > 0) {
            return d.comments.some((d) => d.uName === uName);
          }
          return false;
        });

        if (filteredTicketData.length) {
          return {
            ...acc,
            [`${vehicleName}|${invoice}`]: [...filteredTicketData],
          };
        } else {
          return {
            ...acc,
          };
        }
      }, {});
    },
    filterSearchText(text) {
      return Object.entries(data).reduce((acc, curr) => {
        let [key, value] = curr;
        let { vehicleName, invoice } = value[0];

        let filteredTicketData = value.filter((d) => {
          let searchStr = Object.values(d).reduce((acc, curr) => {
            if (curr instanceof Array) {
              let commentsStr = "";
              if (curr && curr.length > 0) {
                curr.forEach((c) => {
                  commentsStr += " " + c.cmt + " ";
                  //commentsStr += " " + c.dt + " ";
                  //commentsStr += " " + c.stkt + " ";
                  //commentsStr += " " + c.sn + " ";
                });
              }
              return acc + " " + commentsStr;
            }
            if (!curr) {
              return acc;
            }
            return acc + " " + String(curr);
          }, " ");

          return searchStr.toLowerCase().includes(text.toLowerCase());
        });

        if (filteredTicketData.length) {
          return {
            ...acc,
            [`${vehicleName}|${invoice}`]: [...filteredTicketData],
          };
        } else {
          return {
            ...acc,
          };
        }
      }, {});
    },
    filterTicketStatusType(type) {
      if (type === "all") {
        return data;
      } else {
        return Object.entries(data).reduce((acc, curr) => {
          let [key, value] = curr;
          let { vehicleName, invoice } = value[0];

          let filteredTicketData = value.filter(
            (d) => Number(d.statusOfTicket) === Number(type)
          );
          if (filteredTicketData.length) {
            return {
              ...acc,
              [`${vehicleName}|${invoice}`]: [...filteredTicketData],
            };
          } else {
            return {
              ...acc,
            };
          }
        }, {});
      }
    },
  };
};

const user = JSON.parse(
  localStorage.getItem("user") != null
    ? base64_decode(localStorage.getItem("user"))
    : null
);

const DatePickerFilterComponent = React.forwardRef(
  ({ handleSelectDateRange, ...props }, ref) => {
    const [startDate, setStartDate] = useState(
      new Date(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000))
    );
    const [endDate, setEndDate] = useState(new Date());

    const [minStart, setMinStart] = useState(
      new Date(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
    );
    const [maxEnd, setMaxEnd] = useState(new Date());

    const handleStartDate = (date) => {
      //console.log("statr-- ", typeof date,date);
      setStartDate(date);
    };
    const handleEndDate = (date) => {
      //console.log("end-- ", date);
      setEndDate(date);
    };

    useEffect(() => {
      setMinStart(new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000));
    }, [endDate]);

    useEffect(() => {
      if (
        new Date().getTime() >
        startDate.getTime() + 7 * 24 * 60 * 60 * 1000
      ) {
        setMaxEnd(new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000));
      }
    }, [startDate]);

    const handleDatePickerDisplay = () => {
      let ele = document.getElementById("datePickerDiv");
      if (ele.style.display == "none") {
        ele.style.display = "flex";
        document
          .querySelector(".datePickerDivParent")
          .classList.add("blackedElem");
        document.querySelector(".datePickerDivButton").style.color = "white";
        document
          .querySelector(".datePickerDivParent")
          .classList.remove("coloredElem");
      } else {
        ele.style.display = "none";
        document
          .querySelector(".datePickerDivParent")
          .classList.remove("blackedElem");
        document
          .querySelector(".datePickerDivParent")
          .classList.add("coloredElem");
        document.querySelector(".datePickerDivButton").style.color = "black";
      }
    };

    const updateDateRange = () => {
      if (!startDate) {
        alert("Please select Start Date!");
      }
      if (!endDate) {
        alert("Please select End Date!");
      } else {
        handleSelectDateRange(startDate, endDate);
        handleDatePickerDisplay();
      }
    };

    return (
      <div
        style={{ position: "relative" }}
        className="datePickerDivParent lgbtn centerMyElement coloredElem listViewIdentifier"
      >
        <button
          className="datePickerDivButton"
          style={{ border: "0", background: "transparent" }}
          onClick={handleDatePickerDisplay}
        >
          <span ref={ref}>Start-Date</span>
          <img src={DropDownIcon} alt="Search" style={{ marginLeft: "10px" }} />
        </button>

        <div id="datePickerDiv" style={{ display: "none" }}>
          <button
            className="datePickerCloseBtn"
            style={{}}
            onClick={handleDatePickerDisplay}
          >
            <img
              src={CloseIcon}
              alt="chats"
              style={{ width: "10px", height: "10px" }}
            />
          </button>
          <div>
            From :
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDate(date)}
              selectsStart
              //startDate={startDate}
              //endDate={endDate}
              minDate={minStart}
              maxDate={endDate}
            />
          </div>
          <div>
            To:
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDate(date)}
              selectsEnd
              //startDate={startDate}
              //endDate={endDate}
              minDate={startDate}
              maxDate={maxEnd}
            />
          </div>
          <div
            className="flex"
            style={{
              justifyContent: "flex-end",
              flexDirection: "column",
              marginRight: "20px",
            }}
          >
            <button
              className="blackedElem"
              style={{ borderRadius: "10px", height: "25px" }}
              onClick={updateDateRange}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
);

const groupTicketsByInvoiceAndVehicleName = (data) => {
  return data.reduce((acc, curr) => {
    let { vehicleName, invoice } = curr;
    return {
      ...acc,
      [`${vehicleName}|${invoice}`]: acc[`${vehicleName}|${invoice}`]
        ? [...acc[`${vehicleName}|${invoice}`], { ...curr }]
        : [{ ...curr }],
    };
  }, {});
};

export const Dashboard = () => {
  const [data, setData] = useState({});
  const [originalRes, setRes] = useState({});
  const [duplicateData, setDuplicateData] = useState({});
  const [companydata, setCidData] = useState({});
  const [serviceAletsData, setAlertsData] = useState({});
  const logintype = localStorage.getItem("UserType") || "";
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const [viewMode, setViewMode] = useState("list");
  //const originalDataRef = useRef(null);

  const [dropdown1, setDropdown1] = useState({
    selectedOption: "",
    isOpen: false,
  });
  const [dropdown2, setDropdown2] = useState({
    selectedOption: "",
    isOpen: false,
  });
  let cid = 0;

  const handleSelect = (dropdownId, value) => {
    const promise2 = [];
    if (recordsList.current)
      recordsList.current.classList.add("simulateLoading");
    cid = value ? value : cid;
    if (dropdownId === 1) {
      setDropdown1({ selectedOption: value, isOpen: false });
      localStorage.setItem("serviceId", value);
      if (value == 0) {
        setData(duplicateData);
        if (recordsList.current)
          recordsList.current.classList.remove("simulateLoading");
      } else {
        let filterData = filterServiceData(value, originalRes);
        setData(filterData);
        if (recordsList.current)
          recordsList.current.classList.remove("simulateLoading");
      }
    } else {
      if (value == 0) {
        localStorage.setItem("cidname", "All Plants");
      } else {
        localStorage.setItem("cidname", "Single");
      }
      if (localStorage.getItem("Cid") != value) {
        promise2.push(
          updateControlTicketData(cid, providerId, dateRange, "notallow")
        );
      }
      setDropdown2({ selectedOption: value, isOpen: false });
      Promise.all(promise2) // Use Promise.all with the promises array
        .then(function (response) {
          recordsList.current.classList.remove("simulateLoading");
        })
        .catch(function (error) {
          // An error occurred in one of the promises
          console.error(error);
        });
    }
  };

  const filterServiceData = (value, data) => {
    const filteredData = data.filter((item) => {
      return item.service_id === value;
    });
    const groupedData = groupTicketsByInvoiceAndVehicleName(filteredData);
    return groupedData;
  };

  const toggleDropdown = (dropdownId) => {
    if (dropdownId === 1) {
      setDropdown1((prevState) => ({
        ...prevState,
        isOpen: !prevState.isOpen,
      }));
      setDropdown2({ isOpen: false, selectedOption: dropdown2.selectedOption });
    } else {
      setDropdown2((prevState) => ({
        ...prevState,
        isOpen: !prevState.isOpen,
      }));
      setDropdown1({ isOpen: false, selectedOption: dropdown1.selectedOption });
    }
  };

  const closeDropdown = useCallback((dropdownId) => {
    if (dropdownId === 1) {
      setDropdown1((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
    } else {
      setDropdown2((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
    }
  }, []);

  const [dateRange, setDateRange] = useState({
    fromDt: formatDateLocal(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "fromDt"
    ),
    toDt: formatDateLocal(new Date(), "toDt"),
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTicketStatusType, setSelectedTicketStatusType] =
    useState("all");

  let {
    user: {
      company_id: companyId,
      provider_id: providerId,
      userName: loggedInUser,
    },
    user,
  } = useSelector((state) => state.authentication);

  const recordsList = useRef(null);
  const dateRangeTxt = useRef(null);
  const firstUpdate = useRef(true);

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const handleViewChange = () => {
    if (viewMode == "calender") {
      document.querySelector(".rightTopSubBottomRow").style.display = "flex";
      setViewMode("list");
      setSideBarOpen(true);
    } else if (viewMode == "list") {
      document.querySelector(".rightTopSubBottomRow").style.display = "none";
      setViewMode("calender");
      setSideBarOpen(false);
    }
  };

  const updateControlTicketData = (companyId, providerId, dateRange, value) => {
    return new Promise(function (resolve, reject) {
      if (recordsList.current) {
        recordsList.current.classList.add("simulateLoading");
      }
      if (companyId == null) companyId = "";
      getControlTicketData({
        companyId: companyId == null ? 0 : companyId,
        providerId: providerId,
        fromDt: dateRange.fromDt,
        toDt: dateRange.toDt,
      }).then(
        (res) => {
          setRes(res.result);
          resolve();
          let groupedData = groupTicketsByInvoiceAndVehicleName(res.result);
          setDuplicateData(groupedData);
          if (localStorage.getItem("serviceId") != "0") {
            let sid = localStorage.getItem("serviceId");
            let filterData = filterServiceData(parseInt(sid), res.result);
            setData(filterData);
          } else {
            setData(groupedData);
          }
          if (value != "notallow") {
            if (recordsList.current) {
              recordsList.current.classList.remove("simulateLoading");
            }
          }
          let dateTxtStr =
            formatYYYYMMDD(dateRange.fromDt) +
            " - " +
            formatYYYYMMDD(dateRange.toDt);
          dateRangeTxt.current.innerHTML = dateTxtStr;
        },
        () => {
          if (value != "notallow") {
            if (recordsList.current) {
              recordsList.current.classList.remove("simulateLoading");
            }
          }
        }
      );
    });
  };
  const updateCompanyDropdown = () => {
    return new Promise(function (resolve, reject) {
      getCidData({
        providerId: providerId,
      }).then((res) => {
        resolve();
        let companyData = res.result.result;
        companyData.unshift({ company_id: 0, company_name: "All Plants" });
        setCidData(companyData);
      });
    });
  };
  const updateServiceAlertsdropdown = () => {
    getServiceAlertsData({
      providerId: providerId,
    }).then((res) => {
      let serviceAlertsData = res.result.result;
      console.log("res", res);
      serviceAlertsData.unshift({
        service_id: 0,
        service_name: "All Services",
      });
      setAlertsData(serviceAlertsData);
    });
  };
  const promises = [];
  useEffect(() => {
    if (viewMode == "list") {
      localStorage.setItem("serviceId", "0");
      promises.push(
        updateControlTicketData(companyId, providerId, dateRange, "")
      );
    }
  }, [dateRange, viewMode]);

  useEffect(() => {
    promises.push(updateCompanyDropdown());
    Promise.all(promises) // Use Promise.all with the promises array
      .then(function (response) {
        updateServiceAlertsdropdown();
      })
      .catch(function (error) {
        // An error occurred in one of the promises
        console.error(error);
      });
  }, []);

  /**
   * @param {number} dataKey
   * @param {string} ticketid
   * @param {object} commentBody
   * @param {number} newStatusOfTicket
   */
  const addCommentToTicket = (
    dataKey,
    ticketid,
    commentBody,
    newStatusOfTicket
  ) => {
    console.log({ dataKey, ticketid, commentBody, newStatusOfTicket });
    setData({
      ...data,
      [dataKey]: [
        ...data[dataKey].map((d) => {
          if (parseInt(d.ticketid) === parseInt(ticketid)) {
            let comments = d.comments
              ? [commentBody, ...d.comments]
              : [commentBody];
            return {
              ...d,
              lastModifiedDate: new Date(commentBody.dt * 1000).toISOString(),
              statusOfTicket: newStatusOfTicket,
              comments: [...comments],
            };
          }
          return { ...d };
        }),
      ],
    });
  };

  const handleTicketStatusFilter = (type) => {
    //console.log("fliters function is called");
    //start date filter close if opened
    let ele = document.getElementById("datePickerDiv");
    if (ele.style.display !== "none") {
      ele.style.display = "none";
      document
        .querySelector(".datePickerDivParent")
        .classList.remove("blackedElem");
      document
        .querySelector(".datePickerDivParent")
        .classList.add("coloredElem");
      document.querySelector(".datePickerDivButton").style.color = "black";
    }

    setSelectedTicketStatusType(type);
  };

  const queryData = (forDownload = false) => {
    let recordLengthEl = document.querySelector("#recordsLengthLabel");
    if (!forDownload) {
      //only if its not for download option then we need to updates length of records and loader

      if (recordsList.current) {
        recordsList.current.classList.add("simulateLoading");
      }

      if (!searchTerm && !selectedTicketStatusType) {
        if (recordLengthEl) {
          //recordsLength.current.innerHTML = `Records(${Object.keys(data)?.length || 0})`
          recordLengthEl.innerHTML = `Records (${
            Object.keys(data)?.length || 0
          })`;
        }

        if (recordsList.current) {
          recordsList.current.classList.remove("simulateLoading");
        }

        return data;
      }
    }

    let filteredData = { ...data };

    if (
      selectedTicketStatusType !== "all" &&
      selectedTicketStatusType !== "forMe"
    ) {
      filteredData = filterRecords(filteredData).filterTicketStatusType(
        selectedTicketStatusType
      );
    }

    if (selectedTicketStatusType === "forMe") {
      filteredData =
        filterRecords(filteredData).filterCommentsOfUser(loggedInUser);
    }

    if (searchTerm) {
      filteredData = filterRecords(filteredData).filterSearchText(searchTerm);
    }

    if (!forDownload) {
      if (recordLengthEl) {
        //recordsLength.current.innerHTML = `Records(${Object.keys(data)?.length || 0})`
        recordLengthEl.innerHTML = `Records (${
          Object.keys(filteredData)?.length || 0
        })`;
      }

      if (recordsList.current) {
        recordsList.current.classList.remove("simulateLoading");
      }
    }

    return filteredData;
  };

  const handleClickDownload = () => {
    let ticketsData = queryData(true); //grouped data

    let downloadData = [];

    for (let key in ticketsData) {
      let tempData = ticketsData[key]; //1 groupedtickets //array of objects

      //loop through every tickets
      tempData.forEach((d) => {
        //loop through every ticket object
        let {
          ticketid,
          vehicleName,
          serviceName,
          statusOfTicket,
          invoice,
          remarks,
          dtTime,
          lastModifiedDate,
          comments,
          msg,
        } = d; //array of objects

        let {
          productName,
          Quantity,
          consignee,
          origin,
          destination,
          driverMobileNo,
        } = JSON.parse(remarks);

        if (comments?.length > 0) {
          //0 or null case
          for (let i = 0; i < comments.length; i++) {
            let dataToBeAdded = {};
            const { text: ticketType } = getColorCodeFromType(statusOfTicket);
            let { cmt, dt, sn, stkt, uName } = comments[i];
            dataToBeAdded.TicketId = ticketid;
            dataToBeAdded.VehicleNo = vehicleName;
            dataToBeAdded.InvoiceNo = invoice;
            dataToBeAdded.Consignee = consignee;
            dataToBeAdded.ProductName = productName;
            dataToBeAdded.ServiceName = serviceName;
            dataToBeAdded.Quantity = Quantity;
            dataToBeAdded.DriverMobileNo = driverMobileNo;
            dataToBeAdded.Origin = origin;
            dataToBeAdded.Destination = destination;
            dataToBeAdded.TicketStatus = ticketType;
            dataToBeAdded.TicketDate = formatDateAMPM(new Date(dtTime));
            dataToBeAdded.CommentSn = sn;
            dataToBeAdded.CommentUser = uName;
            dataToBeAdded.CommentStatus = ticketType;
            dataToBeAdded.CommentDate = epochDatetoLocale(dt);
            dataToBeAdded.Remark = cmt;
            dataToBeAdded.Msg = msg;

            downloadData.push(dataToBeAdded);
          }
        } else {
          let dataToBeAdded = {};
          const { text: ticketType } = getColorCodeFromType(statusOfTicket);
          dataToBeAdded.TicketId = ticketid;
          dataToBeAdded.VehicleNo = vehicleName;
          dataToBeAdded.InvoiceNo = invoice;
          dataToBeAdded.Consignee = consignee;
          dataToBeAdded.ProductName = productName;
          dataToBeAdded.ServiceName = serviceName;
          dataToBeAdded.Quantity = Quantity;
          dataToBeAdded.DriverMobileNo = driverMobileNo;
          dataToBeAdded.Origin = origin;
          dataToBeAdded.Destination = destination;
          dataToBeAdded.TicketStatus = ticketType;
          dataToBeAdded.TicketDate = formatDateAMPM(new Date(dtTime));
          dataToBeAdded.CommentSn = "";
          dataToBeAdded.CommentUser = "";
          dataToBeAdded.CommentStatus = "";
          dataToBeAdded.CommentDate = "";
          dataToBeAdded.Remark = "";
          dataToBeAdded.Msg = msg;

          downloadData.push(dataToBeAdded);
        }
      });
    }

    downloadExcel(downloadData);
  };

  const updateSearchTerm = debouncedFn(function (val) {
    setSearchTerm(val);
  }, 800);

  const handleSearchInputChange = (e) => {
    updateSearchTerm(e.target.value);
  };

  const handleSelectDateRange = (fromDt, toDt) => {
    setDateRange({
      fromDt: formatDateLocal(fromDt),
      toDt: formatDateLocal(toDt),
    });
  };

  return (
    <div id="cttMain" className="CustomScrollBar  CustomScroll">
      <div id="cttMain__leftMenu">
        <button
          className="lgbtn centerMyElement"
          style={{
            background: "transparent",
            width: "75%",
            margin: "15px auto 0",
            border: "0",
          }}
        >
          <Link to="/login">
            <img src={Logout} alt="Logout" style={{ width: "22px" }} />
          </Link>
        </button>
      </div>
      <div id="cttMain__Content" className="CustomScrollBar CustomScroll">
        <LeftContentComponent isLeftPanelOpen={sidebarOpen} />

        <div
          className={`${
            sidebarOpen ? "rightPanelNotFull" : "rightPanelFull"
          } cttMain__Content__rightInfo`}
          id="cttMain__rightSection"
        >
          <div className="cttMain__rightTopSection" id="cttMain__rightTop">
            <div className="rightTopSubBottomRow">
              <div className="searchIpDiv listViewIdentifier">
                <label htmlFor="searchIp" id="recordsLengthLabel">
                  Records ({Object.keys(data)?.length || 0})
                </label>
                <input
                  className="searchIp"
                  placeholder="Search Record"
                  onChange={handleSearchInputChange}
                ></input>
                <img src={RecordSearch} alt="Search" />
              </div>
              <DatePickerFilterComponent
                handleSelectDateRange={handleSelectDateRange}
                ref={dateRangeTxt}
              />

              {Array.from([0, 1, 2, 3, 4, 5, "all", "forMe"]).map((d, i) => {
                let bgColor,
                  text,
                  border = "";

                if (d != "all" && d != "forMe") {
                  bgColor = getColorCodeFromType(d).bgColor;
                  text = getColorCodeFromType(d).text;
                  border = getColorCodeFromType(d).border;
                }

                if (d == "all") {
                  return (
                    <button
                      key="all"
                      className={`listViewIdentifier ${
                        selectedTicketStatusType === String(d)
                          ? " blackedElem"
                          : "coloredElem"
                      } lgbtn centerMyElement`}
                      onClick={() => handleTicketStatusFilter("all")}
                    >
                      All
                    </button>
                  );
                } else if (d == "forMe") {
                  return (
                    <button
                      key="forMe"
                      className={`listViewIdentifier ${
                        selectedTicketStatusType === String(d)
                          ? " blackedElem"
                          : "coloredElem"
                      } lgbtn centerMyElement`}
                      onClick={() => handleTicketStatusFilter("forMe")}
                      style={{
                        background: "rgba(115, 85, 79, 0.1)",
                        border: "1px solid #73554F",
                      }}
                    >
                      For Me - {loggedInUser}
                    </button>
                  );
                } else {
                  //{ text == "Open" ? "Open-24" : text }
                  return (
                    <button
                      key={d}
                      className={`listViewIdentifier ${
                        selectedTicketStatusType === String(d)
                          ? "blackedElem"
                          : "coloredElem"
                      } lgbtn centerMyElement `}
                      onClick={() => handleTicketStatusFilter(`${d}`)}
                      style={{
                        background: bgColor,
                        border: `1px solid ${border}`,
                        borderRadius: "15px",
                      }}
                    >
                      {text}
                    </button>
                  );
                }
              })}

              <button
                className="coloredElem lgbtn centerMyElement"
                onClick={handleViewChange}
              >
                Change View
              </button>
              <button
                className="coloredElem lgbtn centerMyElement"
                onClick={handleClickDownload}
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.000000pt"
                  height="16.000000pt"
                  viewBox="0 0 128.000000 128.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M507 1212 c-15 -16 -17 -45 -17 -194 l0 -176 -110 -4 c-116 -3 -130
                                -9 -130 -53 0 -23 367 -395 390 -395 23 0 390 372 390 395 0 44 -14 50 -130
                                53 l-110 4 0 177 c0 223 8 211 -152 211 -99 0 -117 -3 -131 -18z"
                    />
                    <path
                      d="M22 417 c-21 -22 -22 -33 -22 -171 0 -143 1 -148 25 -171 l24 -25
                                591 0 591 0 24 25 c24 23 25 28 25 173 0 204 15 192 -241 192 l-195 0 -54 -56
                                c-66 -67 -96 -84 -150 -84 -54 0 -84 17 -150 84 l-54 56 -196 0 c-192 0 -197
                                0 -218 -23z m956 -216 c4 -28 -14 -51 -42 -51 -47 0 -63 57 -24 84 19 13 25
                                14 43 2 11 -7 21 -23 23 -35z m200 0 c8 -50 -55 -70 -83 -27 -18 27 -11 54 19
                                66 27 10 60 -10 64 -39z"
                    />
                  </g>
                </svg>
              </button>
              <Dropdown
                selectedOption={dropdown1.selectedOption}
                isOpen={dropdown1.isOpen}
                handleSelect={(value) => handleSelect(1, value)}
                toggleDropdown={() => toggleDropdown(1)}
                closeDropdown={() => closeDropdown(1)}
                options={serviceAletsData}
                id={1}
                placeholder="All Services"
              />
              {logintype === "P" && (
                <>
                  <Dropdown
                    selectedOption={dropdown2.selectedOption}
                    isOpen={dropdown2.isOpen}
                    handleSelect={(value) => handleSelect(2, value)}
                    toggleDropdown={() => toggleDropdown(2)}
                    closeDropdown={() => closeDropdown(2)}
                    options={companydata}
                    id={2}
                    placeholder="All Plants"
                  />
                </>
              )}
            </div>
          </div>

          <div
            id="cttMain__rightBottom"
            ref={recordsList}
            className={`cttMain__rightbottomSection CustomScrollBar CustomScroll`}
          >
            {viewMode === "list" ? (
              <TicketsListContainer
                forceUpdate={Math.random() * 100}
                filteredData={Object.entries(queryData())}
                addCommentToTicket={addCommentToTicket}
                viewMode={viewMode}
                ref={firstUpdate}
                searchTerm={searchTerm}
              />
            ) : (
              <div id="calenderView" className=" CustomScrollBar CustomScroll">
                <Calender
                  companyId={companyId}
                  providerId={providerId}
                  handleViewChange={handleViewChange}
                />
              </div>
            )}
          </div>

          <button className="leftPanelToggleBtn" onClick={handleViewSidebar}>
            <img
              src={sidebarOpen ? leftArrow : rightArrow}
              alt="arrow"
              style={{ width: "22px" }}
            />
            {/*<img src={Logout} alt="Logout" style={{ width: "22px" }} />*/}
          </button>
        </div>
      </div>
    </div>
  );
};

const TicketsListContainer = React.forwardRef(
  (
    { forceUpdate, filteredData, addCommentToTicket, viewMode, searchTerm },
    ref
  ) => {
    //const [ticketsData, setTicketsData] = useState([]);
    const [initialTicketData, setInitialTicketData] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const height =
      document.getElementById("cttMain__rightBottom")?.clientHeight - 30 || 450;

    useEffect(() => {
      let deepClonedData = JSON.parse(JSON.stringify([...filteredData]));
      setInitialTicketData([...deepClonedData].slice(0, 15));
      setHasMore(true);
    }, [forceUpdate]);

    const getNextTickets = () => {
      if (initialTicketData.length >= filteredData.length) {
        setHasMore(false);
        return;
      }
      let nextTicketData = [
        ...initialTicketData.concat(
          [...filteredData].slice(
            initialTicketData.length,
            initialTicketData.length + 15
          )
        ),
      ];
      setTimeout(() => {
        setInitialTicketData(nextTicketData);
      }, 500);
    };

    //loader = {
    //    initialTicketData.length ? (<center style={{ margin: '20px' }}><div className="spinner-border" role="status">
    //        <span className="visually-hidden">Loading...</span>
    //    </div></center>) : (<div></div>)
    //}

    useEffect(() => {
      if (viewMode == "list" && !ref.current) {
        document.querySelector(".infiniteScrollDiv").style.height = 350 + "px";
      }

      ref.current = false;
    }, []);

    return (
      <InfiniteScroll
        dataLength={initialTicketData.length}
        next={getNextTickets}
        hasMore={hasMore}
        height={height}
        className={"CustomScroll CustomScrollBar infiniteScrollDiv"}

        //
        //style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        //inverse={true} //
        //scrollableTarget="scrollableDiv"
      >
        {initialTicketData.map(([key, value], index) => {
          // key must be unique
          return (
            <TicketGroupedComponent
              searchTerm={searchTerm}
              key={key}
              uid={key}
              addCommentToTicket={addCommentToTicket}
              ticketGroupedData={value}
              shouldExpand={false}
            />
          );
        })}
      </InfiniteScroll>
    );
  }
);

function LeftContentComponent(props) {
  var sidebarClass = props.isLeftPanelOpen
    ? "leftPanelOpen"
    : "leftPanelCollapse";

  return (
    <div className={`cttMain__Content__leftInfo ${sidebarClass}`} style={{}}>
      <h2>
        Control <b>Tower</b>
      </h2>
      <div id="chartArea">
        <ControlSummary />
      </div>
    </div>
  );
}

function isCommentLengthEqual(prevProps, nextProps) {
  if (
    !prevProps.ticketGroupedData.comments &&
    !nextProps.ticketGroupedData.comments
  )
    return true; //if no comments in prev as well as next
  if (
    !prevProps.ticketGroupedData.comments &&
    nextProps.ticketGroupedData.comments
  )
    return false; //if no comments in prev but in next

  if (
    prevProps.ticketGroupedData.comments.length <
    nextProps.ticketGroupedData.comments.length
  ) {
    return false;
  }
  return true;
}

export const TicketGroupedComponent = ({
  ticketGroupedData,
  addCommentToTicket,
  shouldExpand,
  ...props
}) => {
  const [isTicketGroupExpanded, setIsTicketGroupExpanded] = useState(
    shouldExpand ? shouldExpand : false
  );

  const [firstTicketData, ...restTicketData] = ticketGroupedData;

  const handleExpandTicket = () => {
    if (ticketGroupedData?.length && ticketGroupedData?.length > 1) {
      setIsTicketGroupExpanded(!isTicketGroupExpanded);
    }
  };
  //console.log("ticketGroupedData- " ,ticketGroupedData)
  return (
    <div
      style={{
        marginBottom: "10px",
        marginTop: `${isTicketGroupExpanded ? "20px" : "10px"}`,
      }}
      className={`${isTicketGroupExpanded ? "isExpandedGroupTickets" : ""}`}
    >
      <TicketComponent
        key={String(firstTicketData.dtTime) + String(firstTicketData.ticketid)}
        ticketData={firstTicketData}
        addCommentToTicket={addCommentToTicket}
        handleExpandTicket={handleExpandTicket}
        isTicketGroupExpanded={isTicketGroupExpanded}
        numTickets={ticketGroupedData.length}
        {...props}
      />

      {isTicketGroupExpanded && (
        <div className="subTicketsContainer">
          {/* <center><p style={{ fontWeight: "500", fontSize: "0.9rem", margin: "7px 0" }}>Tickets against Ticket #{firstTicketData.ticketid}</p></center>*/}
          {restTicketData.map((d, i) => {
            return (
              <TicketComponent
                key={String(d.dtTime) + String(d.ticketid)}
                ticketData={d}
                addCommentToTicket={addCommentToTicket}
                isSubTicket={i + 1}
                {...props}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export const getHighlightedText = (text, type = false) => {
  return (searchTerm) => {
    if (!searchTerm && !text.includes("</a>")) {
      return text;
    }

    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

    if (type === "msg") {
      let p1 = text.substr(0, text.indexOf("<a")),
        p2 = text.substring(text.indexOf("<a"), text.lastIndexOf("</a>") + 4),
        p3 = text.substr(text.indexOf("</a>") + 4, text.length - 1);

      let partsArr = [p1, p2, p3];

      //console.log('partsArr', partsArr, text);
      return (
        <React.Fragment>
          {" "}
          {partsArr.map((p, i) => {
            let parts = [p];
            if (searchTerm) {
              parts = p.split(new RegExp(`(${searchTerm})`, "gi"));
            }

            if (i === 1) {
              return (
                <span
                  key={uuidv4()}
                  dangerouslySetInnerHTML={{ __html: p }}
                ></span>
              );
            } else {
              return (
                <React.Fragment key={uuidv4()}>
                  {parts.map((part, i) => {
                    let flag =
                      part.toLowerCase().trim() ===
                      searchTerm.toLowerCase().trim();
                    if (flag) {
                      return <mark key={i + 100 * Math.random()}>{part}</mark>;
                    }
                    return (
                      <React.Fragment key={uuidv4()}>{part}</React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            }
          })}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {" "}
          {parts.map((part, i) => {
            let flag =
              part.toLowerCase().trim() === searchTerm.toLowerCase().trim();
            if (flag) {
              return <mark key={i + 100 * Math.random()}>{part}</mark>;
            }

            return <React.Fragment key={uuidv4()}>{part}</React.Fragment>;
          })}{" "}
        </React.Fragment>
      );
    }
  };
};

export const TicketComponent = ({
  searchTerm = "",
  ticketData,
  addCommentToTicket,
  lastModifiedKeyValue = null,
  handleExpandTicket = null,
  isTicketGroupExpanded,
  numTickets = null,
  isSubTicket = false,
}) => {
  const [hasErrored, setHasErrored] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoadingAddComment, setIsLoadingAddComment] = useState(false);
  const [isMapModal, setIsMapModal] = useState(false);

  const {
    comments,
    dtTime,
    companyId,
    destTracknumb,
    invoice,
    lastModifiedDate,
    msg,
    providerId,
    ticketid,
    tracknumb,
    vehicleName,
    statusOfTicket,
    serviceName,
  } = ticketData;
  const {
    bgColor,
    text: ticketType,
    border,
  } = getColorCodeFromType(statusOfTicket);

  let {
    user: { userName: loggedInUser },
  } = useSelector((state) => state.authentication);
  let remarkData = JSON.parse(ticketData.remarks);
  //console.log("remarkData- ", remarkData);
  const handleMapToggle = () => {
    setIsMapModal(!isMapModal);
  };

  const handleChatToggle = () => {
    //console.log("handleChatToggle")
    setIsChatOpen(!isChatOpen);
  };

  const handleAddComment = (newComment) => {
    setIsLoadingAddComment(true);
    setHasErrored(false);

    let newStatusOfTicket = newComment?.status
      ? parseInt(newComment?.status)
      : statusOfTicket === null ||
        statusOfTicket === "" ||
        newComment?.status === 0
      ? 0
      : statusOfTicket;

    //console.log('uu', JSON.parse(localStorage.getItem("user"))?.userName, newComment,'statusOfTicket', statusOfTicket)
    saveComment({
      ticketid: parseInt(ticketid),
      companyId: String(companyId),
      providerId: String(providerId),
      vehicleName: String(vehicleName),
      invoice: String(invoice),
      statusOfTicket: parseInt(newStatusOfTicket),
      lastModifiedDate:
        (!lastModifiedDate && new Date().toISOString()) ||
        String(lastModifiedDate),
      comments: {
        sn: (comments && comments.length + 1) || 1,
        stkt: parseInt(ticketid),
        cmt: newComment?.text,
        dt:
          (!lastModifiedDate && new Date().toISOString()) ||
          String(lastModifiedDate),
        uName: loggedInUser,
      },
    }).then(
      () => {
        setIsLoadingAddComment(false);
        //parseInt(String(new Date().getTime()).slice(0, -3))

        addCommentToTicket(
          `${vehicleName}|${invoice}`,
          parseInt(ticketid),
          {
            sn: (comments && comments.length + 1) || 1,
            stkt: parseInt(ticketid),
            cmt: newComment?.text,
            dt: parseInt(String(new Date().getTime()).slice(0, -3)),
            uName: loggedInUser,
          },
          parseInt(newStatusOfTicket),
          lastModifiedKeyValue
        );
      },
      (err) => {
        setIsLoadingAddComment(false);
        setHasErrored({ msg: err });
      }
    );
  };

  //console.log("ticketData - ", ticketData, ticketData.remarks, JSON.parse(ticketData.remarks));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {hasErrored && hasErrored.msg && <Message>{hasErrored.msg}</Message>}

      {isSubTicket && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "8%",
            color: "#879FAA",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {isSubTicket > 10 ? isSubTicket : `0${isSubTicket}`}{" "}
        </div>
      )}
      <div
        className="discussionDiv "
        style={{ paddingLeft: `${isSubTicket ? "0px" : "10px"}` }}
      >
        {isSubTicket && <div className="subTicketLeftDiv"></div>}
        <div style={isSubTicket ? { margin: "0", marginLeft: "15px" } : {}}>
          <div
            className={"dD1 flex alignCenter "}
            style={{ position: "relative" }}
          >
            <div className="flex">
              {/*+ (handleExpandTicket && numTickets ? numTickets -1 >0 ? " discussionDivSolidCircle" : "" :"")   discussionDivSolidCircle  */}
              <div
                className={"statusColr"}
                style={{
                  marginRight: "10px",
                  background: border,
                  fontWeight: "600",
                }}
              >
                {getHighlightedText(ticketType)(searchTerm)}
              </div>
              <div
                className="centerMyElement"
                style={{ marginRight: "10px" }}
                title={invoice}
              >
                <strong>
                  {getHighlightedText(
                    `${
                      invoice.split(",").length > 1
                        ? invoice
                            .split(",")
                            .slice(0, 2)
                            .join(",")
                            .slice(0, 20) + "..."
                        : invoice
                    }`
                  )(searchTerm)}
                </strong>
              </div>

              {/*                        {handleExpandTicket && <button style={{ border: 'none', padding: "8px 10px", borderRadius: "10px" }} className="blackedElem smbtn centerMyElement" onClick={handleExpandTicket}>{numTickets ? numTickets > 1 ? `+${numTickets - 1}` : '+' + 0 : '+' + 0}</button>}*/}
              {isSubTicket && (
                <button
                  style={{
                    border: "none",
                    padding: "8px 13px",
                    borderRadius: "10px",
                    cursor: "text",
                  }}
                  className="coloredElem smbtn centerMyElement"
                >
                  {" "}
                  {isSubTicket}{" "}
                </button>
              )}
            </div>

            <div className="alignCenter" style={{ marginLeft: "auto" }}>
              <span
                className="simpleBorderElem"
                style={{ background: "#2D6F9F", color: "#fff", border: "none" }}
              >
                {getHighlightedText(serviceName)(searchTerm)}
              </span>
            </div>

            <div className="alignCenter">
              <span className="simpleBorderElem">
                {getHighlightedText(
                  formatDateAMPM(new Date(lastModifiedDate || dtTime))
                )(searchTerm)}
              </span>
            </div>
          </div>

          <div className="dD2 flex" style={{}}>
            <div className="" style={{ flexBasis: "40%", maxWidth: "370px" }}>
              <div
                className="flex"
                style={{ justifyContent: "flex-start", marginBottom: "10px" }}
              >
                <span
                  className="centerMyElement coloredElem"
                  style={{
                    marginRight: "10px",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <img src={TicketIcon} alt="Ticket No" />
                </span>
                <span className="centerMyElement">
                  <b>{getHighlightedText(`Ticket #${ticketid}`)(searchTerm)}</b>
                </span>
              </div>
              <div className="flex" style={{ justifyContent: "flex-start" }}>
                <span className="simpleBorderElem centerMyElement">
                  {getHighlightedText(vehicleName)(searchTerm)}
                </span>
                {dtTime && (
                  <span className="simpleBorderElem centerMyElement">
                    {getHighlightedText(formatDateAMPM(new Date(dtTime)))(
                      searchTerm
                    )}
                  </span>
                )}

                <span
                  onClick={handleMapToggle}
                  className="simpleBorderElem coloredElem centerMyElement"
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <img src={mapIcon} alt="map modal" />
                </span>
                {isMapModal && (
                  <Modal
                    onClose={handleMapToggle}
                    rootId="modal-root"
                    setWidth="100%"
                  >
                    <TripAnalysisAndMap
                      tracknumb={tracknumb}
                      companyId={companyId}
                      key="tripAndMap"
                    />
                  </Modal>
                )}
              </div>
            </div>

            <div style={{ flexBasis: "57%" }}>
              <div className="tripRemark">
                <div
                  className="tripRemark__left"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    className="tripRemark__left__originDestParent"
                    style={{ position: "relative", padding: "0  0 0 16px" }}
                  >
                    <div
                      className="tripRemark__left__origin"
                      style={{ position: "relative" }}
                    >
                      <span
                        style={{
                          marginRight: "8px",
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          background: "rgba(175, 229, 163, 1)",
                          border: "2px solid white",
                          borderRadius: "50%",
                          position: "absolute",
                          top: "45%",
                          left: "-16px",
                          transform: "translateY(-5px)",
                        }}
                      ></span>
                      <span style={{}}>
                        {getHighlightedText(remarkData?.origin)(searchTerm)}
                      </span>
                    </div>
                    <div
                      className="tripRemark__left__destination"
                      style={{ position: "relative" }}
                    >
                      <span
                        style={{
                          marginRight: "8px",
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          background: "rgba(239, 121, 121, 1)",
                          border: "2px solid white",
                          borderRadius: "50%",
                          position: "absolute",
                          top: "45%",
                          left: "-16px",
                          transform: "translateY(-5px)",
                        }}
                      ></span>
                      <span style={{}}>
                        {getHighlightedText(remarkData?.destination)(
                          searchTerm
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tripRemark__right">
                  <div className="flex" style={{ flexDirection: "column" }}>
                    <InfoDetails1
                      title="Consignee"
                      value={remarkData?.consignee}
                    />
                    <InfoDetails1
                      title="Quantity"
                      value={remarkData?.Quantity}
                    />
                  </div>
                  <div className="flex" style={{ flexDirection: "column" }}>
                    <InfoDetails1
                      title="Product Name"
                      value={remarkData?.productName}
                    />
                    <InfoDetails1
                      title="Driver Mobile No."
                      value={remarkData?.driverMobileNo}
                      search={true}
                      searchTerm={searchTerm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dD3 flex centerMyElement">
            <div>{getHighlightedText(msg, "msg")(searchTerm)}</div>

            <div
              className="flex centerMyElement openChatIcon"
              onClick={handleChatToggle}
              style={{ cursor: "pointer" }}
            >
              <span
                className="coloredElem centerMyElement"
                style={{ padding: "10px", borderRadius: "10px" }}
              >
                {" "}
                <img src={chatIcon} alt="chats" />
              </span>
              <p>
                <b>{(comments && comments.length) || 0}</b>
              </p>
            </div>
          </div>
        </div>
        {!isSubTicket && (
          <div className="subComponentsExpand" onClick={handleExpandTicket}>
            <img src={SubTicketLink} alt="SubTicketLink" />
            <p style={{ margin: 0, marginLeft: "10px", color: "#FFFFFF" }}>
              {numTickets
                ? numTickets > 1
                  ? `+${numTickets - 1}`
                  : "+" + 0
                : "+" + 0}{" "}
              Tickets
            </p>
          </div>
        )}

        {isChatOpen ? (
          <TicketChatComponent
            searchTerm={searchTerm}
            primaryMsg={msg}
            data={ticketData || []}
            handleChatToggle={handleChatToggle}
            handleAddComment={handleAddComment}
            isLoadingAddComment={isLoadingAddComment}
            key="t12345"
          />
        ) : null}
      </div>
    </div>
  );
};

export function TicketChatComponent({
  data,
  handleAddComment,
  isLoadingAddComment,
  primaryMsg,
  handleChatToggle,
  searchTerm,
}) {
  const {
    comments,
    dtTime,
    companyId,
    destTracknumb,
    invoice,
    lastModifiedDate,
    msg,
    providerId,
    ticketid,
    tracknumb,
    vehicleName,
    statusOfTicket,
  } = data;
  const [commentText, setCommentText] = useState({
    text: "",
    status:
      statusOfTicket == null ? 0 : statusOfTicket >= 0 ? statusOfTicket : 0,
  });
  const [ticketStatusPopup, setTicketStatusPopup] = useState(false);

  const isCommentValid = Boolean(commentText.text);
  //console.log("comments-- ", comments);
  const handleChange = (e) => {
    //console.log(e.target.value)

    setCommentText({
      ...commentText,
      text: e.target.value,
    });
  };

  const handleCommentStatusPopupDisplay = () => {
    setTicketStatusPopup(!ticketStatusPopup);
  };

  const handleCommentStatusPopup = (statusInfo = null) => {
    if (statusInfo === null || statusInfo === "" || statusInfo === "-1") {
      ticketStatusPopup && setTicketStatusPopup(false);
      return;
    }

    setCommentText({
      ...commentText,
      status: statusInfo,
    });
    ticketStatusPopup && setTicketStatusPopup(false);
    //console.log("from handleCommentStatusPopup - ", statusInfo);
  };

  const addComment = () => {
    ticketStatusPopup && setTicketStatusPopup(false);
    if (isCommentValid) {
      handleAddComment(commentText);
      setCommentText({ ...commentText, text: "" });
    }
  };

  const modalCommentPopup = useRef(null);

  const {
    bgColor,
    text: ticketType,
    border,
  } = getColorCodeFromType(statusOfTicket);

  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: "1",
      }}
    >
      <Draggable>
        <div
          id="dD4__Id"
          className="dD4"
          style={{
            marginBottom: "0",
            //top: `${100 + Math.floor(Math.random() * 20)}px`,
            //left: `${Math.floor(Math.random() * 20)}px`
          }}
          ref={modalCommentPopup}
        >
          <div style={{ fontSize: "12px" }}>
            <div
              className="statusColr "
              style={{ background: bgColor, border: `1px solid ${border}` }}
            >
              {ticketType}
            </div>

            <div className="flex centerMyElement">
              <strong>{getHighlightedText(vehicleName)(searchTerm)}</strong>
              <span style={{ color: "#879FAA", marginLeft: "5px" }}>
                {getHighlightedText(`Ticket - #${ticketid}`)(searchTerm)}
              </span>
            </div>

            <div
              className="flex centerMyElement"
              style={{ position: "relative" }}
            >
              <span
                className="coloredElem centerMyElement"
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  marginRight: "8px",
                }}
              >
                <img
                  src={chatIcon}
                  alt="chats"
                  style={{ width: "10px", height: "10px" }}
                />
              </span>
              <p>
                <b>{comments ? comments.length : 0}</b>
              </p>
            </div>
            <div
              className="centerMyElement"
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <button
                className="datePickerCloseBtn"
                style={{ position: "unset", height: "23px" }}
                onClick={() => {
                  handleChatToggle();
                }}
              >
                <img
                  src={CloseIcon}
                  alt="chats"
                  style={{ width: "10px", height: "10px" }}
                />
              </button>
            </div>
          </div>

          <div
            style={{
              padding: "0",
              position: "relative",
              margin: "15px auto 0",
              minHeight: data.length > 1 ? "" : "200px",
            }}
          >
            <div className="dD4__ChatOverflowDiv">
              {comments?.map((d, i) => {
                // key must be unique
                //console.log("comment ",d)

                return (
                  <div
                    className="dD4_chat"
                    style={{ display: "flex" }}
                    key={String(d.dt)}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <img
                        src={UserIcon}
                        alt="User Icon"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                    <div>
                      <div className="flex">
                        <div style={{ display: "flex" }}>
                          <b>{d.uName}</b>
                          <span style={{ marginLeft: "6px" }}>
                            {epochDatetoLocale(d.dt)}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <p>{getHighlightedText(d.cmt)(searchTerm) ?? ""}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="dD4_chatIp"
            style={{ padding: "0", marginBottom: "0" }}
          >
            {/*position: data.length <= 1 ? "absolute" : "fixed", width: data.length > 1 ? "" : "100%"*/}
            <input
              placeholder="Add Comment (Type Something)"
              value={commentText.text}
              onChange={handleChange}
              required={true}
            />

            <div>
              <button
                onClick={handleCommentStatusPopupDisplay}
                style={{
                  border: "0",
                  marginRight: "7px",
                  padding: "6px",
                  cursor: "pointer",
                  borderRadius: "10px",
                  background: "#E0EBF1",
                }}
              >
                <img
                  style={{ width: "20px" }}
                  src={threeDots}
                  alt="Ticket Status"
                />
              </button>

              <div
                className="CommentStatusPopupDiv"
                style={{ display: ticketStatusPopup ? "block" : "none" }}
              >
                <CommentStatusPopUp
                  key={`comment__${String(new Date().getMilliseconds())}`}
                  handleCommentStatusPopup={handleCommentStatusPopup}
                  statusOfTicket={commentText.status}
                />
              </div>
            </div>

            <span
              className="blackedElem dD1_searchbtn"
              onClick={addComment}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              <img src={SendIcon} alt="Send" />
            </span>
            {isLoadingAddComment && (
              <img
                style={{ padding: "10px" }}
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}
          </div>
        </div>
      </Draggable>
    </div>,
    document.getElementById("modal-root1")
  );
}

class CommentStatusPopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.statusOfTicket,
      style: this.getCustomStyle(
        this.props.statusOfTicket == null ? 0 : this.props.statusOfTicket
      ),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getCustomStyle = (type) => {
    switch (type) {
      case 0:
        return {
          background: "rgba(175, 229, 163, 0.1)",
          border: "1px solid #AFE5A3",
        };
      case 1:
        return {
          background: " rgba(255, 203, 107, 0.1)",
          border: "1px solid #FFCB6B",
        };
      case 2:
        return {
          background: "rgba(254, 143, 41, 0.1)",
          border: "1px solid #FE8F29",
        };
      case 3:
        return {
          background: "rgba(242, 47, 47, 0.1)",
          border: "1px solid #F22F2F",
        };
      case 4:
        return {
          background: "rgba(123, 109, 209, 0.1)",
          border: "1px solid #7B6DD1",
        };
      case 5:
        return {
          background: "rgba(138, 175, 249, 0.1)",
          border: "1px solid #8AAFF9",
        };
      default:
        return {
          //0 case
          background: "rgba(175, 229, 163, 0.1)",
          border: "1px solid #AFE5A3",
        };
    }
  };

  handleChange(event) {
    this.setState({
      status: Number(event.target.value),
      style: this.getCustomStyle(Number(event.target.value)),
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    //console.log("this.state.size- ", this.state.status)

    this.props.handleCommentStatusPopup(this.state.status);
  }

  render() {
    //console.log("this.state.size- ", this.state)
    return (
      <form onSubmit={this.handleSubmit}>
        <p className="title" style={{ margin: "0 0 10px 0" }}>
          Select the ticket status:
        </p>

        <ul
          style={{
            listStyleType: "none",
            padding: "0 0 0 5px",
            margin: "0 0 10px 0",
          }}
        >
          {Array.from([0, 1, 2, 3, 4, 5]).map((d, i) => {
            return (
              <li
                key={d}
                style={{
                  border:
                    this.state.status == d
                      ? this.state.style.border
                      : "1px solid #E0EBF1",
                  background:
                    this.state.status == d
                      ? this.state.style.background
                      : "white",
                }}
              >
                <label>
                  <input
                    type="radio"
                    value={d}
                    checked={this.state.status === d}
                    onChange={this.handleChange}
                    className={`test test${getColorCodeFromType(d).text}`}
                    style={{ cursor: "pointer" }}
                  />
                  {getColorCodeFromType(d).text}
                </label>
              </li>
            );
          })}
        </ul>

        <div className="centerMyElement">
          <button
            type="submit"
            className=" coloredElem"
            style={{ width: "50%", border: "0" }}
          >
            OK
          </button>
        </div>
      </form>
    );
  }
}

function formatDateAMPM(date) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dateNum = date.getDate();
  var month = date.getMonth();

  var year = date.getFullYear();

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  //var strTime =dateNum+" "+monthNames[month]+" "+year+" "+ hours + ':' + minutes + ' ' + ampm;
  var strTime =
    dateNum +
    " " +
    monthNames[month] +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    ampm;

  return strTime;
}

function epochDatetoLocale(date1) {
  let myDate = new Date(date1 * 1000);

  let dt = myDate.toString().split(" ").slice(1, 4); // [ "Apr", "15", "2022", ]
  let dtTime = myDate.toLocaleString().split(",")[1].toUpperCase(); // 10:20:01 AM

  let result = `${dtTime} ${dt[1]} ${dt[0]} ${dt[2]}`;
  return result;
}

function formatDateLocal(date, y) {
  let local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

function formatYYYYMMDD(date, year = false) {
  //2022-03-31
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let x = date.split("-");
  var strTime = "";

  if (year)
    strTime =
      x[2] + " " + monthNames[Number(x[1]) - 1] + " " + x[0].substr(2, 3);
  else strTime = x[2] + " " + monthNames[Number(x[1]) - 1];
  return strTime;
}

const InfoDetails1 = ({
  title = "No Title",
  value = "No Value Provided",
  search = false,
  searchTerm = "",
}) => {
  return (
    <div>
      <p style={{ color: "#879FAA", fontWeight: "500" }}>{title}</p>
      <p style={{ fontWeight: "500" }}>
        {search ? getHighlightedText(String(value))(searchTerm) : value}
      </p>
    </div>
  );
};
