import { authHeader } from "../_helpers";
import { handleResponse } from "./handleResponse";
import { BASE_URL } from "../_constants";

export const controlSummaryService = {
  getControlSummaryData,
  getControlTicketData,
  getInvoiceWiseData,
};

export function getCidData({ providerId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // http://localhost:14213/api/1.0/ticket/getplant?provide_id=38447
  return fetch(
    `${BASE_URL}/getplant?provide_id=${providerId}`,
    requestOptions
  ).then(handleResponse);
}

export function getServiceAlertsData({ providerId }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // http://localhost:14213/api/1.0/ticket/getplant?provide_id=38447
  return fetch(
    `${BASE_URL}/getalert?provide_id=${providerId}`,
    requestOptions
  ).then(handleResponse);
}

function getControlSummaryData({ companyId, providerId }) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  // http://localhost:14213/api/1.0/ticket/getcontroldatasummary?companyId=38447
  return fetch(
    `${BASE_URL}/getcontroldatasummary?companyId=${companyId}&providerId=${providerId}`,
    requestOptions
  ).then(handleResponse);
}

export function getControlTicketData({ companyId, providerId, fromDt, toDt }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  if (localStorage.getItem("cidname") != "All Plants")
    companyId = companyId == 0 ? localStorage.getItem("Cid") : companyId;
  if (companyId == 0 || companyId == null) {
    companyId = "";
  }
  // http://localhost:14213/api/1.0/ticket/getcontroldata?companyId=38447&providerId=1112&fromDt=03%2F12%2F22&toDt=03%2F13%2F22
  return fetch(
    `${BASE_URL}/getcontroldata?companyId=${companyId}&providerId=${providerId}&fromDt=${fromDt}&toDt=${toDt}`,
    requestOptions
  ).then(handleResponse);
}

export function getInvoiceWiseData({ companyId, providerId, fromDt, toDt }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  companyId = "";
  //http://localhost:14213/api/1.0/ticket/getinvoicewise?companyId=307&providerId=307&fromDt=2022-03-30&toDt=2022-03-30
  return fetch(
    `${BASE_URL}/getinvoicewise?companyId=${companyId}&providerId=${providerId}&fromDt=${fromDt}&toDt=${toDt}`,
    requestOptions
  ).then(handleResponse);
}

export function getControlDataByInvoice({ companyId, providerId, invoice }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  /*    companyId = '';*/
  return fetch(
    `${BASE_URL}/getcontroldata-by-invoice?companyId=${companyId}&providerId=${providerId}&invoiceNo=${invoice}`,
    requestOptions
  ).then(handleResponse);
}

//http://localhost:14213/api/1.0/ticket/getcontroldata-by-invoice?companyId=11&providerId=1667&invoiceNo=NBH23INV000805

// http://www.thexyz.biz:8085/api/1.0/ticket/tripRouteMapData?companyId=35310&tracknumb=240412070298777

export function getTripRouteMapData({ companyId, tracknumb }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  /*    companyId = '';*/
  return fetch(
    `${BASE_URL}/tripRouteMapData?companyId=${companyId}&tracknumb=${tracknumb}`,
    requestOptions
  ).then(handleResponse);
}
