import { authHeader } from '../_helpers';
import { handleResponse } from './handleResponse';
import { BASE_URL } from '../_constants';
import {encode as base64_encode} from 'base-64';
export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
};
function login(username, password,userType) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username,
            password: password,
            userType: userType || 'P',
        }),
    };
    return fetch(`${BASE_URL}/login`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            if (user.isStatus) {
                localStorage.setItem('user',base64_encode(JSON.stringify(user.result)));
                return user;
            } else {
                console.log(user.result);
                return user;
            }
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    console.log('r2');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };

    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}
