import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { getColorCodeFromType } from '../../_helpers/getColorCode';
import styles from './DonutChart.module.css';


const formatIndianSystem = (value) => {

    let val = Math.abs(value);

    if (val >= 10000000) {
        val = (val / 10000000).toFixed(1) + 'Cr';
    } else if (val >= 100000) {
        val = (val / 100000).toFixed(1) + 'L';
    }
    else if (val >= 1000) {
        val = (val / 1000).toFixed(1) + 'K';
    }
    return val;
}


export const DonutChart = ({
    w = 400,
    h = 300,
    title = 'Ticket Stats',
    isLoading,
    ...props
}) => {
    const padding = 40;
    const width = w;
    const height = h;
    const radius = Math.min(width - padding, height - padding) / 2;
    const innerRadius = radius - 15;
    const outerRadius = radius;

    const ref = useRef(null); // group - g element
    const cache = useRef(props.data);
    let totalTickets =  props.data.reduce((acc, curr) => {
        return acc + curr.noOfTicket
    }, 0);

    const createPie = d3
        .pie()
        .value((d) => d.noOfTicket)
        .sort(null);

    const createArc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    useEffect(() => {
        const data = createPie(props.data);
        const prevData = createPie(cache.current);

        // store group g in a variable
        const group = d3.select(ref.current);

        // select prev arcs and bind them with the data
        const groupWithData = group.selectAll('g.arc').data(data);

        // remove extra arcs
        groupWithData.exit().remove();

        // add arcs for unbinded data
        const groupWithUpdate = groupWithData
            .enter()
            .append('g')
            .attr('class', 'arc');

        // append path in new arcs and merge with prev arcs with path
        const path = groupWithUpdate
            .append('path')
            .merge(groupWithData.select('path.arc')); //grpwithupdate+grpwithdata

        const arcTween = (d, i) => {
            const interpolator = d3.interpolate(prevData[i], d);

            return (t) => createArc(interpolator(t));
        };

        // fill colors
        path
            .attr('class', 'arc')
            .attr('fill', (d, i) => {                
                let { border } = getColorCodeFromType(d.data.statusOfTicket)
                return border;
            })//callback function has access to __data__
            .transition()
            .attrTween('d', arcTween);

        // update cache
        cache.current = props.data;
 
    }, [props.data]);
    //style={{ flex: '0.8', alignSelf: 'center' }}
    return (
        <div className={styles.donutContainer}>

            <span className={`${styles.donutTitle} donutTitle`}>{title}</span>


            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '5px', justifyContent: "flex-end" }}>
                <div >

                    {isLoading ? (<div className="spinner-grow" role="status" style={{ display: 'block', margin: '0 auto'}}></div>) : (

                    <svg
                            viewBox={`0 0 ${width} ${height}`}
                            preserveAspectRatio="xMinYMin meet"
                            style={{ width: '100%'}}
                    >
                        <g ref={ref} transform={`translate(${width / 2} ${height / 2})`}>
                            <circle
                                cx="0"
                                cy="0"
                                r={radius + padding / 3}
                                fill="none"
                                stroke="rgba(224, 235, 241, 1)"
                                strokeWidth="1"
                            ></circle>
                            <g>
                                <rect
                                    transform="translate(-55,-85)"
                                    width="110"
                                    height="55"
                                    rx="10"
                                    fill="rgba(224, 235, 241, 1)"
                                    ></rect>
                                    <title>{totalTickets}</title>
                                <text
                                    y="-50"
                                    textAnchor="middle"
                                    style={{ fontWeight: '600', fontSize: '28px' }}
                                        
                                >
                                        {formatIndianSystem(totalTickets)}
                                </text>
                            </g>

                            <text style={{ fontSize: '30px' }} y="10" textAnchor="middle">
                            Total
                            </text>
                            <text style={{ fontSize: '30px' }} y="40" textAnchor="middle">
                            Tickets
                            </text>
                        </g>
                    </svg>
                    )}

              
                </div>
                {!isLoading && <div className={`${styles.donutLabelsContainer} CustomScroll`}>
                    {props.data.sort((a, b) => {
                        return b.noOfTicket - a.noOfTicket;
                        })
                        .map(({ statusOfTicket, noOfTicket }) => {
                            return (
                                <div key={statusOfTicket} className={styles.donutLabelContainer}>
                                    <div className={styles.donutLabelCount}
                                        style={{ background: getColorCodeFromType(statusOfTicket)['bgColor'], border: `1px solid ${getColorCodeFromType(statusOfTicket)['border']}` }}
                                    >
                                        <span title={noOfTicket}>{formatIndianSystem(noOfTicket)}</span>
                                    </div>
                                </div>
                             );
                        })
                    }
                </div>}

            </div>
        </div>

    );
};
//  width={width} height = { height }
