import React, { useRef, useEffect, useCallback } from "react";
import "./Dropdown.css"; // Import the CSS file for styling

export let companyid = "";

const Dropdown = ({
  selectedOption,
  isOpen,
  handleSelect,
  toggleDropdown,
  options,
  closeDropdown,
  id,
  placeholder,
}) => {
  const dropdownRef = useRef(null);
  if (id == 2) {
    localStorage.setItem("Cid", selectedOption);
    companyid = selectedOption;
  }

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    },
    [closeDropdown]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const getDisplayLabel = (selectedOption, options) => {
    if (!selectedOption) {
      return placeholder;
    }

    let selectedOptionLabel = "";
    selectedOptionLabel =
      id == 2
        ? options.find((option) => option.company_id === selectedOption)
            .company_name
        : options.find((option) => option.service_id === selectedOption)
            .service_name;

    if (selectedOptionLabel.length > 31) {
      return selectedOptionLabel.substring(0, 31) + "...";
    }

    return selectedOptionLabel;
  };

  return (
    <div
      className="dropdown-container"
      ref={dropdownRef}
      style={{ marginRight: id === 2 ? "0px" : "0.8%" }}
    >
      <div
        className={`custom-dropdown ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        <span>{getDisplayLabel(selectedOption, options)}</span>
        <span className="arrow">{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li
              key={id == 2 ? option.company_id : option.service_id}
              onClick={() =>
                handleSelect(id == 2 ? option.company_id : option.service_id)
              }
            >
              {id == 2 ? option.company_name : option.service_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
