import { userConstants } from '../_constants';
import {decode as base64_decode} from 'base-64';
//base64_decode(
    console.log(localStorage.getItem('user'));
let user = JSON.parse(localStorage.getItem('user')!=null?base64_decode(localStorage.getItem('user')):null);
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user,
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        default:
            return state;
    }
}
