import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { controlSummaryActions } from '../../_actions';
import { DonutChart } from '../DonutChart';

function ControlSummary(props) {

    useEffect(() => {
        props.getControlSummary({ companyId: props.companyId, providerId: props.providerId })
    },[])
    
    return (
        <>
            <DonutChart h={300} w={700} id="donutChart1" data={props.data.totalcontrolData} isLoading={ props.isLoading} title="Ticket Stats"/>
            <DonutChart h={300} w={700} id="donutChart2" data={props.data.last24hrlData} isLoading={props.isLoading} title="24 Hours Ticket Stats"/>
            <DonutChart h={300} w={700} id="donutChart3" data={props.data.last7DaylData} isLoading={props.isLoading} title="7 Day Ticket Stats"/>
        </>
    );
}

function mapState(state) {
    const { isLoading, data } = state.controlSummary;
    const { user: { company_id: companyId, provider_id: providerId },user } = state.authentication;
    console.log(state)
    return { isLoading, data, companyId, providerId };
}

const actionCreators = {
    getControlSummary: controlSummaryActions.getControlSummary,
    
};

const connectedControlSummary = connect(mapState, actionCreators)(ControlSummary);
export { connectedControlSummary as ControlSummary };
