
export const colorCodes = {
    0:{
        text: "Open",
        bgColor: "rgba(175, 229, 163, 0.1)",
        border: "rgba(175, 229, 163, 1)"
    },
    1:{
        text: "Pending",
        bgColor: "rgba(255, 203, 107, 0.1)",
        border: "rgba(255, 203, 107, 1)"
    },
    2:{
        text: "InProcess",
        bgColor: "rgba(254, 143, 41, 0.1)",//
        border:"rgba(254, 143, 41, 1)"
    },
    3:{
        text: "Closed",
        bgColor: "rgba(242, 47, 47, 0.1)",
        border: "rgba(242, 47, 47, 1)"
    },
    4:{
        text: "Escalated",
        bgColor: "rgba(123, 109, 209, 0.1)",
        border: "rgba(123, 109, 209, 1)"
    },
    5:{
        text: "Reopen",
        bgColor: "rgba(138, 175, 249, 0.1)",
        border: "rgba(138, 175, 249, 1)"
    },

}


/**
 * 
 * @param {(string|number)} type
 */

export const getColorCodeFromType = (type) => {

   
    if (type !== null && !isNaN(type) ) {
        return colorCodes[type]
    }

    if (type && typeof type === 'string') {
        return colorCodes[Object.keys(colorCodes).find(d => colorCodes[d]['text'].toLowerCase() === type.toLowerCase())]
    }
   
    return {
        text: "Open",
        bgColor: "rgba(175, 229, 163, 0.1)",
        border: "rgba(175, 229, 163, 1)"
    }
    
}