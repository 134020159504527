import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { getWeekDates, timeSeries } from "./utility";
import CloseIcon from '../../Assets/CloseButton.svg';
import leftArrow from '../../Assets/leftarrowWithOutTail.svg';
import rightArrow from '../../Assets/right_arrow_withoutTail.svg';
import styles from "./calendar.module.css";
import Draggable from "react-draggable";
import { getInvoiceWiseData, getControlDataByInvoice } from '../../_services/controlSummary.service';
import { v4 as uuidv4 } from 'uuid';
import Modal from "../Portal/Portal";
import RecordSearch from '../../Assets/RecordSearch.svg';
import { TicketGroupedComponent, TicketComponent, TicketChatComponent, debouncedFn,getHighlightedText } from "../Dashboard/Dashboard";
import { colorCodes } from '../../_helpers';
import Message from '../Message';


const Calendar = ({ companyId, providerId, handleViewChange}) => {
    const [data, setData] = useState([])
    const [dateRange, setDateRange] = useState({ //7days starting
        from: -6,
        to: 0
    });
    const [weekDates, setWeekDates] = useState(getWeekDates(new Date()));
    const [calendarCells, setCalendarCells] = useState(getCalendarCellData(weekDates))


    const [searchTerm, setSearchTerm] = useState('');
    const [hasErrored, setHasErrored] = useState(false)


    let queriedData = data;
    if (!searchTerm) {
        queriedData = data;
    } else {

        queriedData = Object.entries(data).reduce((acc, curr) => {
            let [key, value] = curr;

            let ifIncludes = false;
            let filteredTickets = value.filter(d => {

                let flag = false;
                let searchStr = d.invoice + d.vehicleName;

                if (searchStr.toLowerCase().includes(searchTerm.toLowerCase())) {
                    flag = true;
                }
                return flag
            })
            if (filteredTickets.length > 0) {
                ifIncludes = true;
            }

            if (ifIncludes) {
                return { ...acc, [key]: [...filteredTickets] }
            } else {
                return { ...acc }
            }


        }, {})
    }


    const updateSearchTerm = debouncedFn(function (val) {
        setSearchTerm(val);
    }, 800)

    const handleSearchInputChange = (e) => {
        updateSearchTerm(e.target.value)
    }

    const handleDateChange = (type) => {
        document.querySelector(".loadingIdentifier").classList.add("simulateLoading");
        if (type === "previous") {
            setWeekDates(getWeekDates(new Date(Date.now() + (dateRange.to - 7) * 24 * 3600 * 1000 )))
            setDateRange(dateRange => ({
                from: dateRange.from - 7,
                to: dateRange.to - 7
            }))
        }
        else if (type === "next") {
            if (dateRange.to + 7 > 0) {
                document.querySelector(".loadingIdentifier").classList.remove("simulateLoading");
                return;//already at latest slot
            }
            else {
                setWeekDates(getWeekDates(new Date(Date.now() + (dateRange.to + 7) * 24 * 3600 * 1000)))
                setDateRange(dateRange => ({
                    from: dateRange.from + 7,
                    to: dateRange.to + 7
                }))
            }
        }
    }

    function getCalenderDataCustomDate(from, to) {
        
        let fromDt = new Date(Date.now() + from * 24 * 3600 * 1000).toISOString().split('T')[0];
        let toDt = new Date(Date.now() + to * 24 * 3600 * 1000).toISOString().split('T')[0];
        if (hasErrored) {
            setHasErrored(false)
        }
       
        getInvoiceWiseData({ companyId, providerId, fromDt, toDt }).then(async (res) => {

            return await Promise.resolve(setData(groupCalData(res.result))).then(() => {
                
                document.querySelector(".loadingIdentifier").classList.remove("simulateLoading");
            });

        }).catch(err => {
            console.log('err', err)
            document.querySelector(".loadingIdentifier").classList.remove("simulateLoading");
            setHasErrored({msg:err})
            
        })

       
    }

    useEffect(() => {
        (async () => {
            await getCalenderDataCustomDate(dateRange.from, dateRange.to);
           
        })()
        
    }, [calendarCells])


    const firstUpdate = useRef(true);

    useEffect(() => {
       
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setCalendarCells(getCalendarCellData(weekDates));
       
    }, [weekDates])
    
  
    return (
        <div>
            {hasErrored && hasErrored.msg && <Message >{hasErrored.msg}</Message>}
            <div className={styles.searchInputContainer}>
                <span onClick={() => { handleDateChange("previous") }} className="centerMyElement" style={{ background: "black", cursor: "pointer", padding: "1px 5px", borderRadius: "5px" }}>
                    <img src={leftArrow} alt="previousBtn" style={{ width: "12px", height: "12px" }} />
                </span>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{marginRight:"12px"}}>
                        <button className='coloredElem lgbtn centerMyElement' onClick={handleViewChange} style={{ border: "0", width: "100px", height: "100%" }}>
                            Change View
                    </button>
                    </div>
                    <div className="searchIpDiv" title="Search on the basis of Vehicle Number or Invoice only.">
                        <input className="searchIp" placeholder="Search Record" onChange={handleSearchInputChange}></input>
                        <img src={RecordSearch} alt="Search" />
                    </div>
                </div>
                <span onClick={() => { handleDateChange("next") }} className="centerMyElement" style={{ background: "black", cursor: "pointer", padding: "1px 5px", borderRadius: "5px" }}>
                    <img src={rightArrow} alt="nextBtn" style={{ width: "12px", height: "12px" }} />
                </span>
            </div>

            <div className={`${styles.container} loadingIdentifier`}>
            <div className={styles.weekDaysContainer}>
                    <div></div>
                {weekDates.map((d) => {
                    let month = d.date.toString().split(" ")[1];
                    return (
                        <div key={uuidv4()}>
                            <div>{d.dayName} </div>
                            <center><span>{d.date.getDate()} { month}</span></center>
                        </div>)
                })}
            </div>
                <div className={`${styles.daysContainer} CustomScroll`}
                    style={{
                        height: `${document.getElementById("cttMain__rightBottom")?.clientHeight - 130 || 400}px`,
                        overflowY:"auto"
                    }}>
                <div className={styles.timeSeriesContainer}>
                    {timeSeries.map((d) => (
                        <div key={uuidv4()}>{d}</div>
                    ))}
                </div>
                    <div className={styles.cellsContainer} id="calendarCellsContainer">
                    {calendarCells.map((d, i) => {
                  
                        if (!queriedData[d]) {
                            return <div key={uuidv4()}></div>;
                        }

                        return (
                            <div key={uuidv4()}>
                                <GroupTicket
                                    ticketsArr={data[d]}
                                    key={uuidv4()}
                                    companyId={companyId}
                                    providerId={providerId}
                                    lastModifiedKeyValue={d}
                                    searchTerm={searchTerm}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            </div>
            </div>
    )
};


let prevTicketHandler = null

const GroupTicket = ({ ticketsArr, companyId, providerId, lastModifiedKeyValue,searchTerm}) => {
    const [isGroupedTicketPopupOpen, setGroupedTicketPopupOpen] = useState(false);
    const [isInvoiceModal, setInvoiceModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const groupedTicketPopupRef = React.useRef(null);
    const [ticketStatus, setTicketStatus] = useState({})

    const[hasErrored,setHasErrored] = useState(false)

    let { vehicleName, invoice } = ticketsArr[0];//for first ticket
    
    const handleShowModalClick = () => {

        setInvoiceModal(!isInvoiceModal)
    }

    const handleGroupStatusForTickets = (x) => {
        setTicketStatus(x);
    }


    const handleGroupedTicketClick = (event) => {

        event.stopPropagation();
        if (prevTicketHandler) {
            prevTicketHandler();
        }
        if (!isGroupedTicketPopupOpen) {// in case its was false
            prevTicketHandler = function () {
                setGroupedTicketPopupOpen(false);
            }
        } else {
            prevTicketHandler = null
        }

        setGroupedTicketPopupOpen(!isGroupedTicketPopupOpen); //open grouped ticket popup

    };

    useLayoutEffect(() => {
        if (groupedTicketPopupRef.current) {

            let popup = groupedTicketPopupRef.current;
            let popupRect = popup.getBoundingClientRect();

            let parentofPopup = document.querySelector('#calendarCellsContainer');
            let parentRect = parentofPopup.getBoundingClientRect();

            if (popupRect.right + 10 >= parentRect.right) {
                let diff = parentRect.right - popupRect.right + 10
                popup.style.left = diff + 'px'
            }

            if (popupRect.bottom + 10 >= parentRect.bottom) {
                let diff = popupRect.bottom - parentRect.bottom + 10
                popup.style.top = '-' + diff + 'px'
            }

        }
    }, [isGroupedTicketPopupOpen])

    const handleGroupInvoiceTicketsClick = (invoice) => {
        if (hasErrored) {
            setHasErrored(false)
        }

        //console.log("invoice--- ", invoice);
        if (invoice == null || invoice == "") {
            alert("This ticket does not have Invoice");
            return;
        }

        if (companyId == null)
            companyId = "";
        getControlDataByInvoice({ companyId, providerId, invoice }).then((res) => {

            //console.log("ivoice data from parent-- ", res.result);
            setInvoiceData(res.result);
            setInvoiceModal(!isInvoiceModal)
            

        }).catch(err => {
            console.log('err', err)
            setHasErrored({msg:err})
        })
        

        
    };

    const addCommentToTicket = (dataKey, ticketid, commentBody, newStatusOfTicket, lastModifiedKeyValue) => {

        let [vehicleName, invoice] = dataKey.split('|')
        setInvoiceData([...invoiceData.map(d => {
            if (d.vehicleName === vehicleName && d.invoice === invoice && d.ticketid === commentBody.stkt) {
                let existingComments = d.comments ? [...d.comments] : [];
                return { ...d, lastModifiedDate: new Date(commentBody.dt * 1000).toISOString(), comments: [commentBody, ...existingComments], statusOfTicket: newStatusOfTicket }
            }
            return d;
        })])
    }
   

    useEffect(() => {
        //console.log("tickertsArr-- ", ticketsArr);
        let numStatusTickets = ticketsArr.reduce((acc, curr) => {
            let { statusTicket } = curr;
            if (statusTicket === null) {
                statusTicket = "0"
            }
            return { ...acc, [statusTicket]: acc[statusTicket] ? acc[statusTicket] + 1 : 1 }
        }, {})
        //console.log(numStatusTickets)

        setTicketStatus(numStatusTickets);

        return () => {
            if (prevTicketHandler) {
                prevTicketHandler()
            }
        }

    }, [])

    useEffect(() => {
        
        if (isInvoiceModal) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "visible";
        }

    },[isInvoiceModal])

    return (
        <>
            <div className={styles.groupedTicketsContainer} onClick={() => handleGroupInvoiceTicketsClick(invoice)}>
                {/*{ticketsArr.length == 1 ? ticketsArr[0] : }<span>{ticketsArr.length - 1}</span>*/}
                {hasErrored && hasErrored.msg && <Message >{ hasErrored.msg}</Message>}

                <div>
                    <p className={styles.title}>Vehicle Number</p>
                    <p style={{ fontWeight: "500" }}>{getHighlightedText(vehicleName)(searchTerm)}</p>
                </div>
                <div style={{ maxWidth:"100%" }}>
                    <p className={styles.title}>Invoice</p>
                    <p className={styles.textOverflow} style={{ fontWeight: "500" }}>{getHighlightedText(invoice)(searchTerm)}</p>
                </div>

                <div className="centerMyElement" style={{width:"100%"}}>
                    {

                        Object.entries(ticketStatus).map(([key, val]) => <span key={ key} title={val} className={styles.makeMeRound} style={{ background:`${colorCodes[key].border}` }}></span>)
                    }
                </div>

                {isGroupedTicketPopupOpen && (
                    <div className={styles.groupedTicketPopup} onClick={(e) => e.stopPropagation()} ref={groupedTicketPopupRef} >
                        <div
                            className={styles.title}
                            style={{ display: "flex", justifyContent: "space-between", padding: "0 7px", marginBottom:"0" }}
                        >
                            <p>Tickets</p>
                            <span onClick={handleGroupedTicketClick} style={{ background: "black", cursor: "pointer", padding: "1px",borderRadius:"3px" }}>
                                <img src={CloseIcon} alt="chats" style={{ width: "12px", height: "12px" }} />
                            </span>
                        </div>
                  
                        <div className={`${styles.subticketsPopUp} CustomScroll`} style={{width:"101%"}}>
                            {ticketsArr.map((ticketData, i) => {
                                //console.log(ticketData )
                                if (i == 0)
                                    return null;
                                return (
                                    <SubTickets
                                        ticketData={ticketData}
                                        companyId={companyId}
                                        providerId={providerId}

                                        lastModifiedKeyValue={lastModifiedKeyValue}
                                        handleGroupInvoiceTicketsClick={handleGroupInvoiceTicketsClick}
                                        searchTerm={searchTerm}
                                        key={`${ticketData.vehicleName}_${ticketData?.lastModifiedDate}_${Math.random() * 100}`}
                                    />
                                );
                            })}
                            </div>
                  

                    </div>
                )}

                
            </div>
            {
                Boolean(ticketsArr.length - 1) &&
                (
                    <button className="openChatFromModal"
                        onClick={handleGroupedTicketClick}>
                        +{ticketsArr.length - 1} More
                    </button>
                )
            }

            {
                isInvoiceModal && (
                    <Modal onClose={handleShowModalClick} isInvoiceModal={isInvoiceModal} rootId="modal-root" >
                        
                        <TicketGroupedComponent
                            key={uuidv4()}
                            addCommentToTicket={addCommentToTicket}
                            ticketGroupedData={invoiceData}
                            shouldExpand={true}
                            lastModifiedKeyValue={lastModifiedKeyValue}
                            searchTerm={searchTerm}
                        />
                                               
                </Modal>
                )
            }


        </>
    );
};

const SubTickets = ({ ticketData, companyId, providerId, handleGroupInvoiceTicketsClick,searchTerm}) => {


    let invoice = ticketData?.invoice;

    const handleSubTicketClick = (event) => {
        event.stopPropagation();

        //console.log("invoice--- ", companyId, providerId ,invoice);
        handleGroupInvoiceTicketsClick(invoice);

    };

    return (
        <div
            style={{}}
            className={styles.subTicket}
            onClick={handleSubTicketClick}
        >
            <p className={styles.title} style={{ display: "inline", marginRight:"7px" }}>Vehicle Num (Invoice)</p>
            <span className={styles.makeMeRound} style={{ background: `${colorCodes[ticketData.statusTicket].border}` }}></span>
            <p className={styles.textOverflow} style={{ overflow: "initial", fontWeight: "500", textOverflow: "initial" }}>
                {getHighlightedText(ticketData.vehicleName)(searchTerm)} {ticketData.invoice ? getHighlightedText(ticketData.invoice)(searchTerm) : ""}
            </p>
        </div>
    );
};

function groupCalData(data) {
    console.log("original- ", data);
    let list = data.reduce((acc, curr) => {
        let { lastModifiedDate } = curr;
        let date = new Date(lastModifiedDate);
        let key = `${date.toLocaleDateString()}|${date.getHours()}`;

        if (acc[key]) {
            return {
                ...acc, [key]: [...acc[key], { ...curr }].sort(function (a, b) {
                    return (a.lastModifiedDate < b.lastModifiedDate) ? -1 : ((a.lastModifiedDate > b.lastModifiedDate) ? 1 : 0);
                })
            };
        } else {
            return { ...acc, [key]: [curr] };
        }
    }, {});
    console.log("altered - ", list);
    return list;
}

function getCalendarCellData(weekDates) {
    let calendarCells = [];
    for (let i = 0; i < timeSeries.length; i++) {
        for (let j = 0; j < weekDates.length; j++) {
            let time = timeSeries[i].split(":")[0];
            calendarCells.push(`${weekDates[j]["localDateString"]}|${time}`);
        }
    }

    return calendarCells;
}

function getLast7days() {
    let d1 = new Date();
    let d2 = new Date(Date.now() - 7 * 24 * 3600);

    return {
        from: d1.toISOString().split('T')[0],
        to: d2.toISOString().split('T')[0]
    }
}
export default Calendar;
