import React from 'react';
import ReactDOM from 'react-dom';//const modalRoot = document.getElementById('modal-root')
import CloseIcon from '../../Assets/CloseButton.svg';

class Modal extends React.Component {

    render() {
        return ReactDOM.createPortal(
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    zIndex: "1"
                }}
                
            >
                <div
                    style={{
                        padding: 20,
                        background: '#fff',
                        borderRadius: '2px',
                        display: 'inline-block',
                        minHeight: '500px',
                        margin: '1rem',
                        position: 'relative',
                        minWidth: '600px',
                        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                        justifySelf: 'center',
                        maxWidth: this.props.setWidth ? this.props.setWidth:"90%",
                        fontSize: "13px",
                        fontFamily: 'Montserrat',
                        borderRadius: "10px"
                        
                    }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px"
                    }}>
                        <button onClick={this.props.onClose} style={{ padding: "0", background: "black", borderRadius: "7px" }}>
                            <img src={CloseIcon} alt="chats" style={{ width: "21px" }} />
                        </button>
                    </div>
                 
                    <div className="CustomScroll" style={{
                        overflowY: "auto",                        
                        height: "80vh",
                        border: "1px solid #aebbd5",
                        padding: "5px",
                        borderRadius: "10px",
                      
                        }}
                    >
                    {this.props.children}
                    </div>
                    
                </div>
            </div>,
            document.getElementById(this.props.rootId),
        )
    }
}


//import { useEffect } from "react";
//import { createPortal } from "react-dom";

//const Modal = ({ children }) => {
//    const mount = document.getElementById("modal-root");
//    const el = document.createElement("div");

//    useEffect(() => {
//        mount.appendChild(el);
//        return () => mount.removeChild(el);
//    }, [el, mount]);

//    return createPortal(children, el)
//};

//export default Modal;

export default Modal;
