import {decode as base64_decode} from 'base-64';
export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(base64_decode(localStorage.getItem('user')));
    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token, "x-api-version": "1.0", "Content-Type": "application/json"};
    } else {
        return { "x-api-version": "1.0", "Content-Type": "application/json" };
    }
}
