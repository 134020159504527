const DAY_NAME = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];
const DAY = 1000 * 60 * 60 * 24;

export function getWeekDates(fromDt) {
    const date = new Date(fromDt);
    
    const arr = [];
    for (let i = 6; i >= 0; i--) {
        let nextDate;
        if (i === 0) {
            nextDate = new Date(date);
            arr.push({
                date: nextDate,
                dayName: DAY_NAME[nextDate.getDay()],
                localDateString: nextDate.toLocaleDateString()
            });
        } else {
            nextDate = new Date(date.getTime() - DAY * i);
            arr.push({
                date: nextDate,
                dayName: DAY_NAME[nextDate.getDay()],
                localDateString: nextDate.toLocaleDateString()
            });
        }
    }
    return arr;
}


const timeSeries = [];
for (let i = 0; i < 24; i++) {
    timeSeries.push(`${i}:00`);
}

export { timeSeries };