import { controlSummaryConstants } from '../_constants';
import { controlSummaryService } from '../_services';
//import { alertActions } from './alert.actions';


export const controlSummaryActions = {
    getControlSummary
}

function getControlSummary({ companyId='',providerId}) {
    if(companyId==null)
    companyId='';
    return (dispatch) => {
        dispatch(request());
        controlSummaryService.getControlSummaryData({ companyId, providerId}).then(
            (data) => {
                console.log('summ data', data)
                dispatch(success(data))
                //dispatch(alertActions.success('summ data fetched'));
            }, (error) => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            })
    }

    function request() {
        return { type: controlSummaryConstants.CONTROL_SUMMARY_REQUEST };
    }
    function success(data) {
        return { type: controlSummaryConstants.CONTROL_SUMMARY_SUCCESS, data };
    }
    function failure(error) {
        return { type: controlSummaryConstants.CONTROL_SUMMARY_ERROR, error };
    }
}
