import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { controlSummary } from './controlSummary.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    controlSummary
});

export default rootReducer;
